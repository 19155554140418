import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { LazyImage } from 'components/Image.tsx';

import type { ThumbnailItemProps, ThumbnailWithPageIdx } from './types.ts';
import { canvasCustomEvents } from './event-actions.ts';

function ThumbnailItem({ data, index, style }: ThumbnailItemProps) {
  const matchIndex = ({ pageIdx }: ThumbnailWithPageIdx) =>
    pageIdx === activePage;
  const { activePage, position, thumbnails } = data;
  const matchedThumbnail = thumbnails.findIndex(matchIndex);

  const handleClick = () => {
    const pageIdx = thumbnails[index].pageIdx;
    canvasCustomEvents.updatePageEvent(pageIdx);
  };

  return (
    <ListItem
      component="div"
      disablePadding
      style={style}
      sx={{
        aspectRatio: 1,
        ...(position === 'left' ? { pl: 2 } : { pr: 2 }),
        pb: 2,
      }}
    >
      <ListItemButton
        disableGutters
        sx={{ borderRadius: 1, p: 0.5 }}
        onClick={handleClick}
      >
        <Box
          sx={{
            border: '1px solid',
            borderColor: ({ palette }) =>
              matchedThumbnail === index ? palette.primary.main : 'transparent',
            borderRadius: 1,
            position: 'relative',
            height: 0,
            pt: '100%',
            overflow: 'hidden',
            width: 1,
          }}
        >
          <LazyImage
            data-testid="thumbnail-image"
            src={thumbnails[index].url}
            width={136}
            style={{
              objectFit: 'contain',
              objectPosition: 'center center',
              height: '100%',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        </Box>
      </ListItemButton>
    </ListItem>
  );
}

export { ThumbnailItem };
