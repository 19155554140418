import type { ReactNode } from 'react';
import { useLayoutEffect, useRef } from 'react';
import Box from '@mui/material/Box';

interface ThumbnailsContainerProps {
  children: ReactNode;
}

function ThumbnailsContainer({ children }: ThumbnailsContainerProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      containerRef.current.style.height = `calc(100vh - ${rect.top + 40}px)`;
    }
  }, []);

  return (
    <Box sx={{ py: 2 }}>
      <Box ref={containerRef} sx={{ height: 1 }}>
        {children}
      </Box>
    </Box>
  );
}

export { ThumbnailsContainer };
