import { FabricImage, FabricObject, Point } from 'fabric';

import type { GetControls } from './types.ts';
import {
  SCALE_STEP,
  ZOOM_IN_THRESHOLD,
  ZOOM_OUT_THRESHOLD,
} from './constants.ts';
import {
  bindObjectsToImage,
  findImage,
  findRect,
  getLatestScale,
  updateBoundObjects,
  zoomToCrop,
} from './canvas-utils.ts';

function getControls(getCanvas: GetControls) {
  const getCenterPoint = () => {
    const canvas = getCanvas();

    return new Point({
      x: (canvas.getWidth() as number) / 2,
      y: (canvas.getHeight() as number) / 2,
    });
  };

  const rotateImage = (counterClockwise = false) => {
    const canvas = getCanvas();
    const objects = canvas.getObjects();
    const image = objects.find(findImage);
    const rect = objects.find(findRect);

    if (image) {
      const angle = image.get('angle');
      image.rotate(angle + (counterClockwise ? -90 : 90));
      canvas.fire('object:rotating');
      updateBoundObjects(canvas);
      canvas.renderAll();

      if (rect) {
        zoomToCrop(canvas, rect);
      }
    }
  };

  const updateScale = ({
    imageObject,
    newScale,
    oldScale,
  }: {
    imageObject: FabricObject;
    newScale: number;
    oldScale: number;
  }) => {
    if (newScale !== oldScale) {
      imageObject.scale(newScale);
    }
  };

  return {
    fitImage: () => {
      const canvas = getCanvas();
      const imageObject = getCanvas().getObjects().find(findImage);
      let scaleFactor = 1;

      if (imageObject) {
        const angle = imageObject.angle;
        const prevScale = imageObject.scaleX;
        // if 0, 180 or 360 degrees then fit to width
        const canvasWidth = canvas.getWidth();
        const objectWidth = imageObject.get('width');
        const objectHeight = imageObject.get('height');

        bindObjectsToImage(canvas);

        if (angle % 180 === 0) {
          scaleFactor = canvasWidth / objectWidth;
        } else {
          scaleFactor = canvasWidth / objectHeight;
        }

        updateScale({
          imageObject,
          newScale: scaleFactor,
          oldScale: prevScale,
        });

        imageObject.set({
          left: canvasWidth / 2,
        });

        const rect = canvas.getObjects().find(findRect);

        if (rect) {
          zoomToCrop(canvas, rect, 1);
          const vpt = canvas.viewportTransform;
          vpt[4] = 0;
        } else {
          canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        }

        updateBoundObjects(canvas);
        canvas.renderAll();
      }
    },
    resetZoomAndPan: () => {
      const canvas = getCanvas();
      const objects = canvas.getObjects();
      const targetObject = objects.find(findImage);

      if (targetObject) {
        bindObjectsToImage(canvas);
        targetObject.scale(getLatestScale(canvas, targetObject as FabricImage));
        canvas.centerObject(targetObject);
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        updateBoundObjects(canvas);
      }
    },
    rotateImageClockwise: () => {
      rotateImage();
    },
    rotateImageCounterClockwise: () => {
      rotateImage(true);
    },
    zoomIn: () => {
      const canvas = getCanvas();
      const zoom = canvas.getZoom();

      if (zoom < ZOOM_IN_THRESHOLD) {
        canvas.zoomToPoint(getCenterPoint(), zoom + SCALE_STEP);
      }
    },
    zoomOut: () => {
      const canvas = getCanvas();
      const zoom = canvas.getZoom();

      if (zoom > ZOOM_OUT_THRESHOLD) {
        canvas.zoomToPoint(getCenterPoint(), zoom - SCALE_STEP);
      }
    },
  };
}

export { getControls };
