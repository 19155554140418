import type { ChangeEvent, FunctionComponent } from 'react';
import type { Theme } from '@mui/material/styles';
import type { TextFieldProps } from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { textFieldClasses } from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import type { ControllerWithRadioGroupProps } from 'components/form/ControllerWithRadioGroup';
import type { ControllerWithTextFieldProps } from 'components/form/ControllerWithTextField';
import type { ControllerWithDatePickerV2Props } from 'components/form/ControllerWithDatePickerV2';
import { ControllerWithRadioGroup } from 'components/form/ControllerWithRadioGroup';
import { ControllerWithTextField } from 'components/form/ControllerWithTextField';
import { ControllerWithDatePickerV2 } from 'components/form/ControllerWithDatePickerV2';
import { canvasCustomEvents } from 'components/CanvasToolV2/event-actions.ts';
import { LIST } from 'constants/field-type';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';
import { isLineLevelItem } from 'state/selectors/documents';

import type { InputFieldNewProps } from './types';
import { DeleteField } from './DeleteField';
import { OcrValue } from './OcrValue';
import { SkipCheckbox } from './SkipCheckbox';
import { SKIP_INPUT_SUFFIX } from './utils';

type NonListComponentMap = {
  bool: FunctionComponent<ControllerWithRadioGroupProps>;
  date: FunctionComponent<ControllerWithDatePickerV2Props>;
  float: FunctionComponent<ControllerWithTextFieldProps>;
  int: FunctionComponent<ControllerWithTextFieldProps>;
  str: FunctionComponent<ControllerWithTextFieldProps>;
  hidden: FunctionComponent<any>;
  NoneType: FunctionComponent<ControllerWithTextFieldProps>;
};

type ListComponentMap = {
  bool: FunctionComponent<any>;
  date: FunctionComponent<any>;
  float: FunctionComponent<any>;
  hidden: FunctionComponent<any>;
  int: FunctionComponent<any>;
  str: FunctionComponent<any>;
  NoneType: FunctionComponent<any>;
};

const nonListInputComponents: NonListComponentMap = {
  bool: ({ color, ...props }) => (
    <ControllerWithRadioGroup
      {...props}
      radioOptions={['true', 'false']}
      getOptionLabel={['form.true', 'form.false']}
      namespace={HITL_TOOL_PAGE}
      radioProps={{
        sx: {
          color: `${color}.main`,
          '&.Mui-checked': {
            color: `${color}.main`,
          },
        },
      }}
    />
  ),
  date: ({ label, name, ...textfieldProps }) => {
    return (
      <ControllerWithDatePickerV2
        label={label}
        name={name}
        textfieldProps={textfieldProps as TextFieldProps}
      />
    );
  },
  float: (props) => <ControllerWithTextField type="number" {...props} />,
  hidden: () => null,
  int: (props) => <ControllerWithTextField type="number" {...props} />,
  str: (props) => <ControllerWithTextField {...props} />,
  NoneType: (props) => <ControllerWithTextField {...props} />,
};

const listInputComponents: ListComponentMap = {
  bool: () => null,
  date: () => null,
  float: () => null,
  hidden: () => null,
  int: (props) => <ControllerWithTextField {...props} />,
  str: (props) => <ControllerWithTextField {...props} />,
  NoneType: () => null,
};

function InputFieldNew(props: InputFieldNewProps) {
  const { t } = useTranslation(HITL_TOOL_PAGE);
  const {
    boundingBox,
    deleted,
    deleteLineItem,
    disabled,
    fieldType,
    isCheck,
    isDocumentTypeValid,
    isValidLineItems,
    isVisuallyHidden,
    label,
    name,
    ocrHistoryResource,
    updateIsCheckList,
    valid,
    value,
    valueType,
  } = props;
  const { control } = useFormContext();
  const skip = !!isCheck?.includes(name);
  const isValid = valid || valid === null;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked;

    if (updateIsCheckList) {
      updateIsCheckList(name, checked);
    }
  }

  function handleFocus() {
    canvasCustomEvents.addCrop(
      {
        boundingBox,
        id: name,
        pageIdx: props.pageIdx || 0,
        valid: isValid,
      } || undefined
    );
  }

  const originalOcrItem = ocrHistoryResource && ocrHistoryResource[name];
  const isLineLevel = isLineLevelItem(props);
  const inputComponent =
    fieldType === LIST ? listInputComponents : nonListInputComponents;
  // @ts-ignore
  const componentAs = inputComponent[valueType] ?? ControllerWithTextField;
  const markup = componentAs({
    color: isValid ? 'success' : 'error',
    disabled: disabled || skip || !isDocumentTypeValid,
    'aria-disabled': skip,
    label,
    name,
    onFocus: handleFocus,
    inputProps: {
      tabIndex: isVisuallyHidden ? -1 : 0,
    },
    InputProps: {
      startAdornment:
        originalOcrItem?.value || value ? (
          <Box
            sx={{
              width: 36,
              cursor: 'help',
            }}
          >
            <OcrValue title={originalOcrItem?.value || value} />
          </Box>
        ) : null,
    },
    sx: {
      opacity: deleted ? 0.5 : 1,
      position: 'relative',
      [`&.${textFieldClasses.root} fieldset`]: {
        borderColor: ({ palette }: Theme) =>
          isValid ? palette.success.main : palette.error.main,
        borderWidth: 2,
      },
      '&:before': {
        borderBottom: '2px solid',
        borderColor: 'grey.500',
        content: deleted ? '" "' : undefined,
        left: -4,
        opacity: 1.5,
        position: 'absolute',
        top: '50%',
        width: 'calc(100% + 8px)',
      },
    },
  });

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        justifyContent: 'space-between',
        display: isVisuallyHidden ? 'none' : 'flex',
      }}
    >
      {markup}
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
          height: 36,
          pt: '18px',
        }}
      >
        <Box
          sx={{
            minWidth: 36,
          }}
        >
          {isLineLevel && isValidLineItems ? (
            <DeleteField
              disabled={!isDocumentTypeValid}
              onDelete={() => deleteLineItem(name)}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            minWidth: 36,
          }}
        >
          {isValidLineItems ? (
            <Controller
              control={control}
              name={`${name}${SKIP_INPUT_SUFFIX}`}
              defaultValue={false}
              render={({ field }) => (
                <SkipCheckbox
                  isChecked={skip}
                  handleChange={handleChange}
                  title={t('form.skip')}
                  disabled={!isDocumentTypeValid}
                  {...field}
                />
              )}
            />
          ) : null}
        </Box>
      </Stack>
    </Stack>
  );
}

export { InputFieldNew };
