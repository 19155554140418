import { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

import type { CropState, UseCropListenerProps } from './types.ts';
import {
  attachEventsToImg,
  bindObjectsToImage,
  findRect,
  getLatestScale,
  updateBoundObjects,
  zoomToCrop,
} from './canvas-utils.ts';
import { configureRect } from './handle-crop.ts';

const STATE_LIMIT = 3;

export enum ActionType {
  ADD_CROP = 'ADD_CROP',
}

type Action = {
  type: ActionType.ADD_CROP;
  crop: CropState;
};

interface State {
  crops: CropState[];
}

const listeners: ((crop: CropState) => void)[] = [];
let internalState: State = {
  crops: [],
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.ADD_CROP:
      return {
        ...state,
        crops: [action.crop, ...state.crops].slice(0, STATE_LIMIT),
      };
    default:
      return state;
  }
}

export function dispatch(action: Action) {
  internalState = reducer(internalState, action);
  listeners.forEach((listener) => listener(action.crop));
}

export function useCropListener({
  canvas,
  onSlide,
  updatePage,
}: UseCropListenerProps) {
  const { palette } = useTheme();

  const addCrop = useCallback(
    (crop: CropState) => {
      const { boundingBox, pageIdx, valid } = crop;

      onSlide(pageIdx, canvas.page).then((img) => {
        updatePage(pageIdx);

        const prevRect = canvas.getObjects().find(findRect);

        if (img) {
          if (prevRect) {
            canvas.remove(prevRect);
          }

          if (boundingBox) {
            const prevAngle = img.angle;

            // reset scale
            img.rotate(0);
            const newScale = getLatestScale(canvas, img);
            img.scale(newScale);

            const rect = configureRect({
              boundingBox,
              canvas,
              img,
              scale: newScale,
              strokeColor: valid ? palette.success.main : palette.error.main,
            });
            canvas.centerObject(img);
            canvas.add(rect);
            bindObjectsToImage(canvas);
            img.rotate(prevAngle);
            canvas.fire('object:rotating');
            updateBoundObjects(canvas);
            zoomToCrop(canvas, rect);
          }

          attachEventsToImg(canvas, img);
        }
      });
    },
    [canvas, onSlide, palette.error.main, palette.success.main, updatePage]
  );

  useEffect(() => {
    listeners.push(addCrop);

    return () => {
      const index = listeners.indexOf(addCrop);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [addCrop]);

  return internalState;
}
