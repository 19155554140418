import type { SyntheticEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import type { ThumbnailsMultiGroupProps } from './types.ts';
import { ThumbnailsContainer } from './ThumbnailsContainer.tsx';
import { ThumbnailMenu } from './ThumbnailMenu.tsx';
import { ThumbnailPanel } from './ThumbnailPanel.tsx';

function ThumbnailsMultiGroup({
  activePage,
  groupedThumbnails,
  position,
  thumbnails,
}: ThumbnailsMultiGroupProps) {
  const firstGroup = Object.keys(groupedThumbnails)[0];
  const [[panel, force], setPanel] = useState([firstGroup, false]);

  const firstLoad = useRef(false);
  const prevPage = useRef(activePage);
  const activePanel = thumbnails?.[activePage].documentGroup;

  const handleChange = (_: SyntheticEvent, newValue: string, force = false) => {
    setPanel([newValue, force]);
  };

  useEffect(() => {
    if (thumbnails && firstLoad.current) {
      const activePanel = thumbnails[activePage].documentGroup;

      // force, to allow the user to navigate back to "menu" without it
      // being locked to the current panel
      if (!force && activePanel !== panel) {
        setPanel([activePanel, false]);
      }
    }

    if (prevPage.current !== activePage) {
      setPanel(([prevPanel]) => [prevPanel, false]);
    }

    firstLoad.current = true;
    prevPage.current = activePage;
  }, [activePage, force, panel, thumbnails]);

  return (
    <TabContext value={panel}>
      <ThumbnailsContainer>
        <TabPanel value="menu">
          <List disablePadding>
            {Object.entries(groupedThumbnails).map(([group], index: number) => (
              <ThumbnailMenu
                key={group}
                group={group}
                handleChange={handleChange}
                index={index}
                isActive={group === activePanel}
              />
            ))}
          </List>
        </TabPanel>

        {Object.entries(groupedThumbnails).map(([group, thumbnails]) => (
          <ThumbnailPanel
            key={group}
            activePage={activePage}
            group={group}
            thumbnails={thumbnails}
            handleChange={handleChange}
            position={position}
          />
        ))}
      </ThumbnailsContainer>
    </TabContext>
  );
}

export { ThumbnailsMultiGroup };
