export function toCamelCase(str: string) {
  return str.replace(/[-_](.)/g, (_, c) => c.toUpperCase());
}

export function toSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, (letter, index) =>
    index === 0 ? letter.toLowerCase() : `_${letter.toLowerCase()}`
  );
}

export function toTitleCase(str: string) {
  const sentence = str.toLowerCase().split('_');

  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0]?.toUpperCase() + sentence[i].slice(1);
  }

  return sentence.join(' ');
}

// convert snake case string to camel case string
export function convertSnakeCaseToCamelCase(string: string) {
  // Split the string by underscores
  const words = string.split('_');

  // Capitalize the first letter of each word after the first
  for (let i = 1; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back together
  return words.join('');
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
