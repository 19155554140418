import type { ReactNode } from 'react';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type { Document, DocumentContent } from 'types/Documents';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { TypographyWithTranslation } from 'components/with-translation';
import { CanvasToolV2 } from 'components/CanvasToolV2';
import { PAGE_ID } from 'constants/route-keys';
import { useLocales } from 'locales/useLocales';
import {
  useGetDocument,
  useGetDocumentContent,
  useGetDocumentHistorical,
  useGetDocumentHistory,
  useGetDocumentHistoryVersion,
  useIsDocumentAwaitingReview,
} from 'state/queries/documents';
import {
  getDocumentErrorMessage,
  getDocumentRejectReason,
  getIsDocumentErrored,
  getIsDocumentCompleted,
  getIsDocumentProcessing,
  getIsDocumentRejected,
  getIsDocumentReviewInProgress,
  getIsDocumentClosed,
} from 'state/selectors/documents';
import { useCurrentUserEmail } from 'state/queries/current-user';
import { generateUuid } from 'utils/generate-uuid';
import { preWarmImageCache } from 'utils/image-cache.ts';
import { updateWindowPerformanceObject } from 'analytics/utils.ts';
import { HIL_TOOL_START } from 'analytics/events.ts';

import type { List, Resources } from './types';
import { AuditTrail } from './AuditTrail';
import { StatusMessage } from './StatusMessage';
import { DocumentToolbar } from './DocumentToolbar';
import { ToolLayout } from './ToolLayout';
import { ItemsContent } from './ItemsContent';
import { FormHandler } from './FormHandler';
import { StickyPanel } from '../StickyPanel.tsx';

type State = {
  list: string[];
  resources: { [key: string]: DocumentContent };
};

interface RootProps {
  closeClaimHilTool?: VoidFunction;
  currentUserEmail: string;
  document: Document;
  fromClaimView?: boolean;
  list: List;
  resources: Resources;
}

interface ToolProps extends RootProps {
  fromClaimView?: boolean;
  specificHistoryData?: Document;
}

interface HilToolWithDocumentProps {
  closeClaimHilTool?: VoidFunction;
  currentUserEmail: string;
  document: Document;
  fromClaimView?: boolean;
  resolvedLanguage: string;
}

interface HilToolProps {
  closeClaimHilTool?: VoidFunction;
  documentId: string;
  fromClaimView?: boolean;
}

function convertArrayToState(content: DocumentContent[]) {
  return content.reduce(
    (cur: State, val) => {
      const id = generateUuid();
      return {
        list: [...cur.list, id],
        resources: {
          ...cur.resources,
          [id]: val,
        },
      };
    },
    { list: [], resources: {} }
  );
}

function isMultipage(document: Document) {
  return document.urls ? Object.keys(document.urls).length > 1 : false;
}

function Tool(props: ToolProps) {
  const { document } = props;
  const t1 = performance.now();

  useEffect(() => {
    updateWindowPerformanceObject(HIL_TOOL_START);
  }, []);

  return (
    <FormHandler
      {...props}
      isMultipage={isMultipage(document)}
      startPerformanceMeasurement={t1}
    />
  );
}

// REVIEW_IN_PROGRESS
function HilInProgress(props: RootProps) {
  const { document } = props;
  const {
    data: specificHistoryData,
    isError,
    isPending,
  } = useGetDocumentHistoryVersion(document.id);

  if (isPending) {
    return <LoadingIcon />;
  }

  if (isError) {
    return (
      <TypographyWithTranslation
        variant="body1"
        i18nKey="common.loadDocumentError"
      />
    );
  }

  return <Tool specificHistoryData={specificHistoryData} {...props} />;
}

// AWAITING_REVIEW
function HilInReview(props: RootProps) {
  const { document } = props;
  // try loading history, if available otherwise let HIL user proceed
  const { data: specificHistoryData } = useGetDocumentHistoryVersion(
    document.id
  );

  return <Tool {...props} specificHistoryData={specificHistoryData} />;
}

// COMPLETED
function HilIsCompleted({
  document,
  fromClaimView,
  list,
  resources,
}: RootProps) {
  const params = useParams();
  const historyParam = params[PAGE_ID];
  const [history, setHistory] = useState(historyParam);
  const { data: historyData, isPending } = useGetDocumentHistory(document.id);
  const historyDataMaps = useMemo(() => {
    if (isPending || !historyData?.length) {
      return [];
    }

    return historyData
      .map((item) => {
        if (item.content) {
          return convertArrayToState(item.content);
        }
        return null;
      })
      .filter(Boolean);
  }, [historyData, isPending]);

  const updateHistory = (value: string | undefined) => {
    setHistory(value);
  };

  if (isPending) {
    return <LoadingIcon />;
  }

  // for COMPLETE stage document let's get the specific history from the
  // array and pass it into the ItemsContent
  const historyListResource = history
    ? historyDataMaps[Number(history)]
    : undefined;
  const hasHistoryData = historyDataMaps.length > 0;
  const itemsContent = (
    <ItemsContent
      isMultipage={isMultipage(document)}
      list={
        hasHistoryData && historyListResource ? historyListResource.list : list
      }
      resources={
        hasHistoryData && historyListResource
          ? historyListResource.resources
          : resources
      }
    />
  );

  const currentDocument =
    history && historyData ? historyData[Number(history)] : document;
  const leftColumn = (
    <Stack sx={{ height: 1 }}>
      <DocumentToolbar document={document} fromClaimView={fromClaimView} />
      <StickyPanel conditionForSticky={true} additionalHeight={52}>
        <CanvasToolV2 urls={document.urls} />
      </StickyPanel>
    </Stack>
  );
  // -85px to account for AuditTrail height
  const rightColumn = (
    <>
      <AuditTrail
        fromClaimView={fromClaimView}
        currentHistory={history}
        isVisible
        {...currentDocument}
        historical
        historySize={historyData?.length || 0}
        updateHistory={updateHistory}
      />
      <Box
        sx={{ height: `calc(100vh - (85px + 64px + 48px))`, overflowY: 'auto' }}
      >
        {itemsContent}
      </Box>
    </>
  );

  return <ToolLayout leftColumn={leftColumn} rightColumn={rightColumn} />;
}

function HilIsReadOnly({ document, list, resources }: RootProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  // this is not ideal using javascript to handle styling
  // but I'll redo all this in hiltool v3
  useLayoutEffect(() => {
    if (containerRef.current) {
      const children = containerRef.current.children;

      if (children.length > 1) {
        const firstChildHeight = children[0].clientHeight;
        (children[1] as HTMLElement).style.height =
          `calc(100% - ${firstChildHeight}px)`;
      }
    }
  }, []);

  const leftColumn = (
    <Stack sx={{ height: 1 }}>
      <DocumentToolbar document={document} />
      <CanvasToolV2 urls={document.urls} />
    </Stack>
  );
  const itemsContent = (
    <ItemsContent
      isMultipage={isMultipage(document)}
      list={list}
      resources={resources}
    />
  );
  let rightColumn: ReactNode = null;

  if (getIsDocumentErrored(document)) {
    rightColumn = (
      <>
        <StatusMessage
          titleI18nKey="stage.error"
          message={getDocumentErrorMessage(document)}
        />
        {itemsContent}
      </>
    );
  }

  if (getIsDocumentRejected(document)) {
    rightColumn = (
      <Box sx={{ height: `calc(100vh - (85px + 48px))`, overflowY: 'auto' }}>
        <Stack ref={containerRef} sx={{ height: 1 }}>
          <StatusMessage
            lastUpdatedBy={document.lastUpdatedBy}
            titleI18nKey="stage.rejectReason"
            message={getDocumentRejectReason(document)}
          />
          {itemsContent}
        </Stack>
      </Box>
    );
  }

  if (getIsDocumentProcessing(document)) {
    rightColumn = (
      <>
        <Box
          sx={{
            p: 2,
          }}
        >
          <TypographyWithTranslation
            i18nKey="stage.processing"
            variant="subtitle1"
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          />
        </Box>
        {itemsContent}
      </>
    );
  }

  if (getIsDocumentClosed(document)) {
    rightColumn = (
      <>
        <Box
          sx={{
            p: 2,
          }}
        >
          <TypographyWithTranslation
            i18nKey="stage.closed"
            variant="subtitle1"
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          />
        </Box>
        {itemsContent}
      </>
    );
  }

  return <ToolLayout leftColumn={leftColumn} rightColumn={rightColumn} />;
}

function HilToolWithDocument(props: HilToolWithDocumentProps) {
  const { document, resolvedLanguage } = props;
  const options = { id: document.id, language: resolvedLanguage };
  const { data: isHistorical } = useGetDocumentHistorical(options);
  const { data: isAwaitingReview } = useIsDocumentAwaitingReview(options);
  const { data: content } = useGetDocumentContent(options);

  const { list, resources } = useMemo(() => {
    if (content?.length) {
      return convertArrayToState(content);
    }

    return { list: [], resources: {} };
  }, [content]);

  if (isAwaitingReview && !isHistorical) {
    return <HilInReview {...props} list={list} resources={resources} />;
  }

  const isReviewInProgress = getIsDocumentReviewInProgress(document);

  if (isReviewInProgress && !isHistorical) {
    return <HilInProgress {...props} list={list} resources={resources} />;
  }

  const isCompleted = getIsDocumentCompleted(document);

  if (isHistorical || isCompleted) {
    return <HilIsCompleted {...props} list={list} resources={resources} />;
  }

  return <HilIsReadOnly {...props} list={list} resources={resources} />;
}

function HilTool({
  closeClaimHilTool,
  documentId,
  fromClaimView = false,
}: HilToolProps) {
  const { resolvedLanguage } = useLocales();
  const documentQuery = useGetDocument(documentId, resolvedLanguage);
  const currentUserEmailQuery = useCurrentUserEmail();

  if (documentQuery.isPending || currentUserEmailQuery.isPending) {
    return <LoadingIcon />;
  }

  if (documentQuery.isError || currentUserEmailQuery.isError) {
    return (
      <TypographyWithTranslation
        variant="body1"
        i18nKey="common.loadDocumentError"
      />
    );
  }

  // preload images
  preWarmImageCache(documentQuery.data.urls);

  return (
    <HilToolWithDocument
      closeClaimHilTool={closeClaimHilTool}
      currentUserEmail={currentUserEmailQuery.data}
      document={documentQuery.data}
      fromClaimView={fromClaimView}
      resolvedLanguage={resolvedLanguage}
    />
  );
}

export default HilTool;
export { HilTool };
