import { useCallback, useEffect } from 'react';

import type { UsePageListenerProps } from './types.ts';

const listeners: ((newPage: number) => void)[] = [];

export function dispatch(newPage: number) {
  listeners.forEach((listener) => listener(newPage));
}

export function usePageListener({ onSlide, updatePage }: UsePageListenerProps) {
  const setPage = useCallback(
    (newPage) => {
      onSlide(newPage).then(() => {
        updatePage(newPage);
      });
    },
    [onSlide, updatePage]
  );

  useEffect(() => {
    listeners.push(setPage);

    return () => {
      const index = listeners.indexOf(setPage);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [setPage]);
}
