import { useSearchParams } from 'react-router-dom';
import { PAGE, QUERY, SEARCH_FIELD } from 'constants/route-keys';

function useAdvancedSearch() {
  const [searchParams, setSearchParams] = useSearchParams();

  function updateSearchParams(
    value: string | undefined,
    searchField?: string | null
  ) {
    // create new search params object
    const latestSearchParams =
      typeof window !== 'undefined' ? window.location.search : '';
    const params = new URLSearchParams(latestSearchParams);

    if (value) {
      if (searchField) {
        params.set(SEARCH_FIELD, searchField);
      }
      params.set(QUERY, value);
      params.set(PAGE, '0');
    } else {
      params.delete(QUERY);
      params.delete(SEARCH_FIELD);
    }

    setSearchParams(params);
  }

  function handleSearch({
    value,
    searchField,
  }: {
    value: string | undefined;
    searchField?: string | null;
  }) {
    const newSearch = value ? value?.replace(/[^a-z\d_-]/gi, '') : '';
    updateSearchParams(newSearch, searchField);
  }

  return {
    handleSearch,
    searchValue: searchParams.get(QUERY) ?? '',
    searchField: searchParams.get(SEARCH_FIELD) ?? '',
  };
}

export { useAdvancedSearch };
