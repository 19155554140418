import type { ChangeEvent, FunctionComponent } from 'react';
import type { Theme } from '@mui/material/styles';
import type { TextFieldProps } from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { textFieldClasses } from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import type { ControllerWithRadioGroupProps } from 'components/form/ControllerWithRadioGroup';
import type { ControllerWithTextFieldProps } from 'components/form/ControllerWithTextField';
import type { ControllerWithDatePickerV2Props } from 'components/form/ControllerWithDatePickerV2';
import { ControllerWithRadioGroup } from 'components/form/ControllerWithRadioGroup';
import { ControllerWithTextField } from 'components/form/ControllerWithTextField';
import { ControllerWithDatePickerV2 } from 'components/form/ControllerWithDatePickerV2';
import { canvasCustomEvents } from 'components/CanvasToolV2/event-actions.ts';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';

import type { InputFieldProps } from './types';
import { SKIP_INPUT_SUFFIX } from './utils';

type NonListComponentMap = {
  bool: FunctionComponent<ControllerWithRadioGroupProps>;
  date: FunctionComponent<ControllerWithDatePickerV2Props>;
  float: FunctionComponent<ControllerWithTextFieldProps>;
  int: FunctionComponent<ControllerWithTextFieldProps>;
  str: FunctionComponent<ControllerWithTextFieldProps>;
  hidden: FunctionComponent<any>;
  NoneType: FunctionComponent<ControllerWithTextFieldProps>;
  dict: FunctionComponent<any>;
};

type ListComponentMap = {
  bool: FunctionComponent<any>;
  date: FunctionComponent<any>;
  float: FunctionComponent<any>;
  hidden: FunctionComponent<any>;
  int: FunctionComponent<any>;
  str: FunctionComponent<any>;
  NoneType: FunctionComponent<any>;
  dict: FunctionComponent<any>;
};

const nonListInputComponents: NonListComponentMap = {
  bool: ({ color, ...props }) => (
    <ControllerWithRadioGroup
      {...props}
      radioOptions={['true', 'false']}
      getOptionLabel={['form.true', 'form.false']}
      namespace={HITL_TOOL_PAGE}
      radioProps={{
        sx: {
          color: `${color}.main`,
          '&.Mui-checked': {
            color: `${color}.main`,
          },
        },
      }}
    />
  ),
  date: ({ label, name, ...textfieldProps }) => {
    return (
      <ControllerWithDatePickerV2
        label={label}
        name={name}
        textfieldProps={textfieldProps as TextFieldProps}
      />
    );
  },
  float: (props) => <ControllerWithTextField type="number" {...props} />,
  hidden: () => null,
  int: (props) => <ControllerWithTextField type="number" {...props} />,
  str: (props) => <ControllerWithTextField {...props} />,
  NoneType: (props) => <ControllerWithTextField {...props} />,
  dict: () => null,
};

const listInputComponents: ListComponentMap = {
  bool: () => null,
  date: () => null,
  float: () => null,
  hidden: () => null,
  int: (props) => <ControllerWithTextField {...props} />,
  str: (props) => <ControllerWithTextField {...props} />,
  NoneType: () => null,
  dict: () => null,
};

export function InputField(props: InputFieldProps) {
  const { t } = useTranslation(HITL_TOOL_PAGE);
  const {
    boundingBox,
    disabled,
    fieldType,
    isCheck,
    isUnidentifiedItem,
    label,
    name,
    updateIsCheckList,
    valid,
    valueType,
  } = props;
  const { register } = useFormContext();
  const skip = !!isCheck?.includes(name);
  const isValid = valid || valid === null;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked;

    if (updateIsCheckList) {
      updateIsCheckList(name, checked);
    }
  }

  function handleFocus() {
    canvasCustomEvents.addCrop(
      {
        boundingBox,
        id: name,
        pageIdx: props.pageIdx || 0,
        valid: isValid,
      } || undefined
    );
  }

  const inputComponent =
    fieldType === 'LIST' ? listInputComponents : nonListInputComponents;
  const componentAs = inputComponent[valueType] ?? ControllerWithTextField;
  const markup = componentAs({
    color: isValid ? 'success' : 'error',
    disabled: disabled || skip,
    'aria-disabled': skip,
    label,
    name,
    onFocus: handleFocus,
    sx: {
      [`&.${textFieldClasses.root} fieldset`]: {
        borderColor: ({ palette }: Theme) =>
          valid || valid === null ? palette.success.main : palette.error.main,
        borderWidth: 2,
      },
    },
  });

  if (isUnidentifiedItem) {
    return (
      <Stack spacing={0.5}>
        {markup}
        <FormControlLabel
          control={
            <Checkbox size="small" checked={skip} onChange={handleChange} />
          }
          label={t('form.skip')}
          {...register(`${name}${SKIP_INPUT_SUFFIX}`)}
        />
      </Stack>
    );
  }

  return markup;
}
