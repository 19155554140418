import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { LinkWithRef } from 'components/Link';
import { useTranslationRoot } from 'components/with-translation';

import type {
  ActionButtonProps,
  DialogDividerProps,
  DialogLinkProps,
} from '../generators';
import { ComponentType } from '../generators';

interface HeaderProps {
  content: string;
  namespace?: string;
}

interface TextProps {
  content: string;
  namespace?: string;
}

const tooltipRenderers = {
  [ComponentType.ACTION_BUTTON](
    {
      action,
      disabled,
      hidden,
      icon,
      isSevere,
      label,
      namespace,
    }: ActionButtonProps,
    i: number
  ) {
    const { t } = useTranslationRoot(namespace);
    const sx = isSevere ? { color: 'error.main' } : undefined;

    return (
      <MenuItem
        key={i}
        disabled={disabled}
        onClick={action}
        sx={{ ...sx, display: hidden ? 'none' : 'flex' }}
      >
        {icon}
        <Typography variant="body2" sx={{ ml: 2 }}>
          {t(label)}
        </Typography>
      </MenuItem>
    );
  },
  [ComponentType.DIVIDER](
    { borderStyle = 'dashed' }: DialogDividerProps,
    i: number
  ) {
    return <Divider key={i} sx={{ borderStyle: borderStyle }} />;
  },
  [ComponentType.HEADER]({ content, namespace }: HeaderProps, i: number) {
    const { t } = useTranslationRoot(namespace);

    return (
      <Box key={i} sx={{ px: 1.5, py: 1 }}>
        <Typography variant="subtitle1">{t(content)}</Typography>
      </Box>
    );
  },
  [ComponentType.LINK](
    { href, icon, label, namespace }: DialogLinkProps,
    i: number
  ) {
    const { t } = useTranslationRoot(namespace);

    return (
      <MenuItem key={i} component={LinkWithRef} to={href}>
        {icon}
        <Typography variant="body2" sx={{ ml: 2 }}>
          {t(label)}
        </Typography>
      </MenuItem>
    );
  },
  [ComponentType.SEVERE_BUTTON](props: ActionButtonProps, i: number) {
    props.isSevere = true;
    return tooltipRenderers[ComponentType.ACTION_BUTTON](props, i);
  },
  [ComponentType.TEXT]({ content, namespace }: TextProps, i: number) {
    const { t } = useTranslationRoot(namespace);

    return (
      <Box key={i} sx={{ px: 1.5, py: 1 }}>
        <Typography variant="body2">{t(content)}</Typography>
      </Box>
    );
  },
};

export { tooltipRenderers };
export type {
  ActionButtonProps,
  DialogDividerProps,
  DialogLinkProps,
  HeaderProps,
};
