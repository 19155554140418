import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { Claim } from 'types/Claims.ts';
import type { ClaimSummaryWidget } from 'types/Widgets.ts';
import { LoadingIcon } from 'components/icons/LoadingIcon.tsx';
import { CanvasToolV2 } from 'components/CanvasToolV2';
import { canvasCustomEvents } from 'components/CanvasToolV2/event-actions.ts';
import { useGetNewClaimPageUrls } from 'state/queries/claims.ts';

import { Layout } from './Layout.tsx';
import { HeaderSummary } from './HeaderSummary.tsx';
import { TimelineWidget } from './TimelineWidget.tsx';
import { Widgets } from './Widgets.tsx';

interface PolicyReviewToolProps {
  claim: Claim;
  widgets: ClaimSummaryWidget[];
}

function PolicyReviewTool({ claim, widgets }: PolicyReviewToolProps) {
  const pageUrlsQuery = useGetNewClaimPageUrls(claim.id);

  const updatePage = (page: number) => {
    // deduct 1 to match page number as array starts from 0
    const newPage = page - 1;
    canvasCustomEvents.updatePageEvent(newPage);
  };

  if (pageUrlsQuery.isPending) {
    return <LoadingIcon />;
  }

  if (pageUrlsQuery.isError) {
    return <Typography>Failed to load claim images.</Typography>;
  }

  const topNode = (
    <Stack direction="row" sx={{ flexGrow: 1 }}>
      <HeaderSummary
        clientClaimId={claim.clientClaimId}
        lastUpdatedOn={claim.lastUpdatedOn}
      />
    </Stack>
  );
  const widgetsNode = (
    <Widgets claim={claim} updatePage={updatePage} widgets={widgets} />
  );
  const canvasNode = (
    <CanvasToolV2 urls={pageUrlsQuery.data.map((urls) => urls.url)} />
  );

  return (
    <Layout
      topNode={topNode}
      timelineNode={
        <TimelineWidget
          claim={claim}
          widgets={widgets}
          updatePage={updatePage}
        />
      }
      widgetsNode={widgetsNode}
      canvasNode={canvasNode}
    />
  );
}

export { PolicyReviewTool };
