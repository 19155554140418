import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ace from 'ace-builds';
import type { AppConfigConfigVersions } from 'types/AppConfig.ts';
import {
  useGetAppConfig,
  useUpdateYamlAppConfig,
} from 'state/queries/appconfig';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { EDIT_ICON, REFRESH_ICON } from 'constants/public-icons.ts';
import { ButtonWithTranslation } from 'components/with-translation.tsx';
import { toast } from 'components/toast/toast.ts';

import { Editor, NAME } from './Editor.tsx';
import { VersionSelector } from './VersionSelector.tsx';
import { Deploy } from './Deploy.tsx';

interface ConfigPanelProps {
  refetchVersions: VoidFunction;
  name: string;
  versions: AppConfigConfigVersions;
}

function ConfigPanel({ refetchVersions, name, versions }: ConfigPanelProps) {
  const getLatestVersion = (versions: AppConfigConfigVersions) => {
    if (versions.length) {
      const config = versions.find((version) => version.isLatest);

      if (config) {
        return config.version;
      }
    }

    return '';
  };

  const latestVersion = useMemo(() => {
    return getLatestVersion(versions);
  }, [versions]);

  const updateAppConfig = useUpdateYamlAppConfig({
    name,
    version: latestVersion,
  });

  const [readOnly, setReadOnly] = useState(true);
  const handleReadOnlyChange = () => {
    setReadOnly(!readOnly);
  };

  const [currentVersion, setCurrentVersion] = useState(latestVersion);
  const handleVersionChange = (value: string) => {
    setCurrentVersion(value);
  };

  const appConfigQuery = useGetAppConfig({
    name,
    version: currentVersion,
  });

  const handleSubmit = () => {
    const editorContent = ace.edit(NAME).getValue();
    setReadOnly(true);

    try {
      updateAppConfig.mutate(
        { body: editorContent, name },
        {
          onSuccess: async () => {
            await refetchVersions();
            const latestVersion = getLatestVersion(versions);
            setCurrentVersion(
              latestVersion ? `${parseInt(latestVersion) + 1}` : '1'
            );
          },
        }
      );
    } catch (e) {
      console.error('Invalid YAML:', e);
    }
  };

  if (appConfigQuery.isError) {
    toast.error(`Failed to fetch ${name} config`);
  }

  return (
    <Stack
      sx={{
        mt: 4,
      }}
      spacing={2}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexBasis: 250 }}>
          <VersionSelector
            handleChange={handleVersionChange}
            value={currentVersion}
            versions={versions}
          />
        </Box>
        <Stack
          direction="row"
          sx={{
            gap: 1,
          }}
        >
          {!!latestVersion && (
            <IconButton
              disabled={appConfigQuery.isPending}
              aria-label="Refresh versions"
              onClick={refetchVersions}
            >
              <SvgIconStyle src={REFRESH_ICON} />
            </IconButton>
          )}
          <ToggleButton
            value="check"
            selected={!readOnly}
            onChange={handleReadOnlyChange}
            size="small"
            sx={{
              '&.Mui-selected': {
                color: 'primary.main',
              },
            }}
          >
            <SvgIconStyle src={EDIT_ICON} />
          </ToggleButton>
          <ButtonWithTranslation
            disabled={readOnly}
            variant="contained"
            color="success"
            i18nKey="configPanel.save"
            onClick={handleSubmit}
            size="small"
          />
          <Deploy name={name} isDisabled={versions.length === 0} />
        </Stack>
      </Stack>

      {appConfigQuery.isPending && versions.length ? (
        <LoadingIcon />
      ) : (
        <Editor config={appConfigQuery.data} readOnly={readOnly} />
      )}
    </Stack>
  );
}

export { ConfigPanel };
