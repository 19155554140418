import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import type { Claim, ClaimObservation } from 'types/Claims';
import type { CurrentUser } from 'types/CurrentUser';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { useNextClaimParams } from 'components/customHooks/useNextClaimParams';
import { PAGE_ID, STAGE } from 'constants/route-keys';
import { AWAITING_REVIEW } from 'constants/claims';
import { CLAIM_TABLE } from 'constants/table-pagination';
import claimsRoute from 'pages/Claims/claims.route';
import claimRoute from 'pages/Claim/claim.route';
import {
  useGetClaimObservations,
  useLockClaim,
  usePatchClaimObservations,
  useUnlockClaim,
} from 'state/queries/claims';
import { getIsClaimAwaitingReview } from 'state/selectors/claims';
import rollbar from 'rollbar-config.ts';
import {
  sendAnalyticEvent,
  updateWindowPerformanceObject,
  getEventTimestamp,
} from 'analytics/utils';
import {
  ENRICHMENT_NEXT_CLAIM_START,
  ENRICHMENT_NEXT_CLAIM_END,
  ENRICHMENT_PATCH_START,
  ENRICHMENT_PATCH_END,
  ENRICHMENT_TOOL_END,
  ENRICHMENT_TOOL_START,
  ENRICHMENT_SUBMIT_END,
  ENRICHMENT_SUBMIT_START,
} from 'analytics/events';

import { WarningDialog } from './WarningDialog';
import { EnrichmentToolContextProvider } from './enrichment-tool-context.tsx';
import { EnrichmentForm } from './EnrichmentForm.tsx';

interface EnrichmentToolV2Props {
  claim: Claim;
  currentUser: CurrentUser;
}

function EnrichmentToolV3({ claim, currentUser }: EnrichmentToolV2Props) {
  const { id: claimId } = claim;
  const navigate = useNavigate();
  const { addToQueue, removeFromQueue } = useOutletContext<{
    addToQueue: (id: string, value: VoidFunction) => void;
    removeFromQueue: (id: string) => void;
  }>();
  const { t } = useTranslationRoot();
  const lockClaimAction = useLockClaim(claimId);
  const unlockClaimAction = useUnlockClaim(claimId);
  const patchObservations = usePatchClaimObservations({
    successMessage: t('enrichmentTool.submitChangesSuccess') as string,
    loadingMessage: t('enrichmentTool.submitChangesLoading') as string,
    errorMessage: (error) =>
      t('enrichmentTool.submitChangesFail', {
        errorMsg:
          (error as unknown as { reason_message: string }).reason_message ||
          (error as unknown as { reason_code: string }).reason_code,
      }),
    gtmEventStart: ENRICHMENT_PATCH_START,
    gtmEventEnd: ENRICHMENT_PATCH_END,
    claimId: claim.id,
  });
  const { data, isPending, isError } = useGetClaimObservations(claimId);
  const enableGetNextClaim = useFeatureFlag('enableGetNextClaim');
  const enableToolDebug = useFeatureFlag('enableDebugMode');
  const getNextClaim = useNextClaimParams();

  useEffect(() => {
    updateWindowPerformanceObject(ENRICHMENT_TOOL_START);
  }, []);

  // update data layer when we navigate to the next claim
  useEffect(() => {
    if (getEventTimestamp(ENRICHMENT_NEXT_CLAIM_START) > 0) {
      sendAnalyticEvent({
        event: ENRICHMENT_NEXT_CLAIM_END,
        performance:
          (Date.now() - getEventTimestamp(ENRICHMENT_NEXT_CLAIM_START)) / 1000,
        claimId,
      });
    }
  }, [claimId]);

  useEffect(function lockClaimOnMount() {
    const { locked } = claim;

    if (!locked && getIsClaimAwaitingReview(claim)) {
      if (enableToolDebug) {
        rollbar.info('Lock claim on mount', { claimId });
      }
      lockClaimAction.mutate(claimId);
      addToQueue(claimId, () => {
        unlockClaimAction.mutate({ id: claimId });
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isError) {
    return (
      <Box sx={{ p: 3 }}>
        <TypographyWithTranslation i18nKey="enrichmentTool.errorLoading" />
      </Box>
    );
  }

  if (isPending) {
    return (
      <Box sx={{ p: 3 }}>
        <LoadingIcon />
      </Box>
    );
  }

  if (!data || !data.length) {
    return (
      <Box sx={{ p: 3 }}>
        <TypographyWithTranslation i18nKey="enrichmentTool.noDocuments" />
      </Box>
    );
  }

  function navigateToClaimsTable() {
    const existingSearch = window.localStorage.getItem(CLAIM_TABLE);
    navigate(
      claimsRoute.createPath({
        search: existingSearch
          ? `?${existingSearch}`
          : `?${STAGE}=${AWAITING_REVIEW}`,
      })
    );
  }

  async function navigateToNextContext() {
    if (!enableGetNextClaim) {
      navigateToClaimsTable();
      return;
    }

    try {
      updateWindowPerformanceObject(ENRICHMENT_NEXT_CLAIM_START);
      const nextClaim = await getNextClaim(true);

      if (nextClaim) {
        const isAwaitingReview = getIsClaimAwaitingReview(nextClaim);
        const to = isAwaitingReview
          ? `${claimRoute.createPath(nextClaim.id)}?${PAGE_ID}=review`
          : claimRoute.createPath(nextClaim.id);
        navigate(to);
      } else {
        navigateToClaimsTable();
      }
    } catch (error) {
      navigateToClaimsTable();
    }
  }

  const onSubmit = (observations: ClaimObservation[]) => {
    const { id } = claim;

    patchObservations.mutate(
      { id, observations },
      {
        onSuccess: async () => {
          removeFromQueue(claimId);
          sendAnalyticEvent({
            event: ENRICHMENT_SUBMIT_END,
            performance:
              (Date.now() - getEventTimestamp(ENRICHMENT_SUBMIT_START)) / 1000,
            claimId: claim.id,
          });
          sendAnalyticEvent({
            event: ENRICHMENT_TOOL_END,
            performance:
              (Date.now() - getEventTimestamp(ENRICHMENT_TOOL_START)) / 1000,
            claimId: claim.id,
            sendToPosthog: true,
          });
          await navigateToNextContext();
        },
      }
    );
  };

  return (
    <EnrichmentToolContextProvider
      claim={claim}
      currentUser={currentUser}
      navigateToNextContext={navigateToNextContext}
    >
      <Card sx={{ overflow: 'hidden', height: { md: 'calc(100vh - 176px)' } }}>
        <EnrichmentForm observations={data} onSubmit={onSubmit} />
      </Card>
      <WarningDialog
        email={currentUser.email}
        lastLockedBy={claim.lastLockedBy}
        locked={claim.locked}
      />
    </EnrichmentToolContextProvider>
  );
}

export { EnrichmentToolV3 };
