import { Canvas as CanvasType, Canvas, FabricObject, Point } from 'fabric';
import { FabricImage, FabricText, util } from 'fabric';

import type {
  AddAndScaleImage,
  AddErrorText,
  AdjustCanvasDimensions,
  BoundingBox,
  CalculateScale,
  CanvasWithPage,
  FabricObjectWithRelationship,
  InitApp,
  LoadNewImage,
  UrlList,
  Urls,
} from './types.ts';

export const findImage = (obj: FabricObject) => obj.isType('image');
export const findRect = (obj: FabricObject) => obj.isType('rect');

function formatUrlObject(url: string) {
  return { url, fileType: 'jpg' };
}

export function getImageList(urls: Urls): UrlList {
  if (Array.isArray(urls)) {
    // if array only contains strings
    if (typeof urls[0] === 'string') {
      return (urls as string[]).map(formatUrlObject);
    }

    return urls as UrlList;
  }

  if (typeof urls === 'object') {
    return Object.values(urls).map(formatUrlObject);
  }

  return [];
}

export function getCurrentUrl(urls: UrlList, page: number): string {
  return urls ? urls[page].url : '';
}

export function loadImage(url: string) {
  return FabricImage.fromURL(url, undefined, {
    hasBorders: false,
    hasControls: false,
    originX: 'center',
    originY: 'center',
    snapAngle: 90,
  });
}

export async function loadNewImage({
  canvas,
  container,
  errorFn,
  imageLoadFinishEvent,
  page,
  urlList,
}: LoadNewImage) {
  const url = getCurrentUrl(urlList, page);
  canvas.clear();

  try {
    const oImg = await loadImage(url);
    imageLoadFinishEvent?.();

    if (oImg && container) {
      const containerRect = container.getBoundingClientRect();
      const opts = {
        canvas,
        containerTop: containerRect.top,
        containerWidth: containerRect.width,
        img: oImg,
      };
      const scale = calculateScale(opts);
      adjustCanvasDimensions({
        ...opts,
        scale,
      });
      addAndScaleImage({ canvas, img: oImg, scale });
      canvas.page = page;
      canvas.fire('page:updated');

      return oImg;
    }
  } catch (error) {
    errorFn(error as unknown as Error);
  }
}

export async function initApp(opts: InitApp) {
  opts.canvas.page = 0;

  const img = await loadNewImage(opts);

  if (img) {
    attachEventsToImg(opts.canvas, img);
  }

  opts.container.style.opacity = '1';
}

export function attachEventsToImg(canvas: CanvasWithPage, img: FabricImage) {
  img.on('moving', () => updateBoundObjects(canvas));
}

export function isPortrait(img: FabricImage) {
  return img.height > img.width;
}

function getMaxHeight(containerTop = 0, verticalPadding = 40) {
  return Math.floor(window.innerHeight - containerTop - verticalPadding - 1); // offset 1px
}

export function calculateScale({
  canvas,
  containerTop,
  containerWidth,
  img,
  showFullHeight = false,
  verticalPadding = 40,
}: CalculateScale) {
  if (showFullHeight) {
    return containerWidth / img.width;
  }

  const maxHeight = getMaxHeight(containerTop, verticalPadding);

  if (isPortrait(img)) {
    return maxHeight / img.height;
  }

  return canvas.width / img.width;
}

export function addAndScaleImage({ canvas, img, scale }: AddAndScaleImage) {
  img.scale(scale);
  img.setCoords();
  canvas.add(img);
  canvas.centerObject(img);
  canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  canvas.setActiveObject(img);
}

export function adjustCanvasDimensions({
  canvas,
  containerTop,
  containerWidth,
  img,
  scale,
  showFullHeight = false,
  verticalPadding,
}: AdjustCanvasDimensions) {
  const maxHeight = getMaxHeight(containerTop, verticalPadding);

  if (showFullHeight) {
    canvas.setDimensions({
      width: containerWidth,
      height: Math.floor(img.height * scale),
    });
  } else {
    const isTallerThanContainer = img.height * scale > maxHeight;

    if (isTallerThanContainer) {
      canvas.setDimensions({
        width: containerWidth,
        height: Math.floor(maxHeight),
      });
    } else {
      canvas.setDimensions({
        width: containerWidth,
        height: Math.floor(img.height * scale),
      });
    }
  }
}

export function addErrorText({
  canvas,
  content,
  error,
  onImageLoadError,
}: AddErrorText) {
  onImageLoadError?.(error as Error);

  const text = new FabricText(content, {
    top: 8,
    left: 8,
    fontFamily: 'Public Sans,sans-serif',
    fontSize: 16,
  });
  canvas.add(text);
}

export function getBoundingBox({ topLeft, bottomRight }: BoundingBox) {
  const [left, top] = topLeft;
  const [right, bottom] = bottomRight;

  return {
    bottom,
    left,
    right,
    top,
  };
}

export function bindObjectsToImage(canvas: Canvas) {
  const objects = canvas.getObjects() as FabricObjectWithRelationship[];
  const img = objects.find(findImage);
  const rects = objects.filter(findRect);

  if (img && rects) {
    const imageTransform = img.calcTransformMatrix();
    const imageTransformInverse = util.invertTransform(imageTransform);

    rects.forEach((rect) => {
      rect.relationship = util.multiplyTransformMatrices(
        imageTransformInverse,
        rect.calcTransformMatrix()
      );
    });
  }
}

export function updateBoundObjects(canvas: Canvas) {
  const objects = canvas.getObjects() as FabricObjectWithRelationship[];
  const img = objects.find(findImage);
  const rects = objects.filter(findRect);

  if (img && rects) {
    rects.forEach((rect) => {
      const { relationship } = rect;
      const newTransform = util.multiplyTransformMatrices(
        img.calcTransformMatrix(),
        relationship
      );
      const opt = util.qrDecompose(newTransform);
      rect.set({
        flipX: false,
        flipY: false,
      });
      rect.setPositionByOrigin(
        new Point({
          x: opt.translateX,
          y: opt.translateY,
        }),
        'center',
        'center'
      );
      rect.set(opt);
      rect.setCoords();
    });
  }
}

export function zoomToCrop(
  canvas: CanvasType,
  object: FabricObject,
  overrideZoom?: number
) {
  const oldZoom = canvas.getZoom();
  canvas.setZoom(1);
  const [, , , , x, y] = object.calcTransformMatrix();
  const zoom = canvas.getZoom();
  const vpw = (canvas.width || 0) / zoom;
  const vph = (canvas.height || 0) / zoom;
  const pan = new Point({ x: x + -(vpw / 2), y: y + -(vph / 2) });
  const point = new Point({ x: vpw / 2, y: vph / 2 });
  canvas.absolutePan(pan);

  let newZoom = oldZoom <= 1 ? 2 : oldZoom;

  if (overrideZoom) {
    newZoom = overrideZoom;
  }

  canvas.zoomToPoint(point, newZoom);
}

export function getLatestScale(canvas: CanvasWithPage, object: FabricImage) {
  const angle = object.get('angle');
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();
  const objectHeight = object.get('height');
  const objectWidth = object.get('width');
  return angle % 180 === 0
    ? isPortrait(object)
      ? canvasHeight / objectHeight
      : canvasWidth / objectWidth
    : isPortrait(object)
      ? canvasWidth / objectHeight
      : canvasHeight / objectWidth;
}
