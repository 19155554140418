import { useState } from 'react';
import { wrap } from 'utils/math.ts';

interface Props {
  initialPage: number;
  totalPage: number;
}

function usePage({ initialPage, totalPage }: Props) {
  const [[page, forward], setPage] = useState([initialPage, 0]);

  const nextPage = () => {
    paginate(1);
  };

  const previousPage = () => {
    paginate(-1);
  };

  const paginate = (newDirection: number) => {
    setPage(([prevPage]) => [prevPage + newDirection, newDirection]);
  };

  const updatePage = (newPage: number) => {
    if (newPage !== page) {
      setPage([newPage, forward]);
    }
  };

  return {
    forward,
    nextPage,
    page: wrap(0, totalPage, page),
    previousPage,
    updatePage,
  };
}

export { usePage };
