import { useEffect } from 'react';
import { getOsKey } from 'utils/client.ts';

import type { UseKeyboardShortcuts } from './types.ts';

function useKeyboardShortcuts({
  hasLoaded,
  nextPage,
  previousPage,
}: UseKeyboardShortcuts) {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const osKey = getOsKey();
      const mainKey = osKey === 'Cmd' ? e.metaKey : e.ctrlKey;

      if (e.key === 'ArrowLeft' && mainKey) {
        e.preventDefault();
        previousPage();
      }

      if (e.key === 'ArrowRight' && mainKey) {
        e.preventDefault();
        nextPage();
      }
    };

    if (hasLoaded) {
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [hasLoaded, nextPage, previousPage]);
}

export { useKeyboardShortcuts };
