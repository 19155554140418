import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { useTranslationRoot } from 'components/with-translation.tsx';
import {
  ARROW_AUTOFIT_WIDTH_ICON,
  MINUS_ICON,
  PLUS_ICON,
  REFRESH_ICON,
  ZOOM_RESET_ICON,
} from 'constants/public-icons.ts';
import { COMMON } from 'constants/translation-keys.ts';

interface ControlsProps {
  fitImage: VoidFunction;
  hasLoaded: boolean;
  resetZoomAndPan: VoidFunction;
  zoomIn: VoidFunction;
  zoomOut: VoidFunction;
  rotateImageCounterClockwise: VoidFunction;
  rotateImageClockwise: VoidFunction;
}

function Controls({
  fitImage,
  hasLoaded,
  resetZoomAndPan,
  rotateImageClockwise,
  rotateImageCounterClockwise,
  zoomIn,
  zoomOut,
}: ControlsProps) {
  const { t } = useTranslationRoot(COMMON);

  return (
    <Stack
      direction="row"
      sx={{
        visibility: hasLoaded ? 'visible' : 'hidden',
      }}
    >
      <Tooltip title={t('canvas.resetZoomAndPan')}>
        <IconButton onClick={resetZoomAndPan}>
          <SvgIconStyle src={ZOOM_RESET_ICON} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('canvas.autoFitWidth')}>
        <IconButton onClick={fitImage}>
          <SvgIconStyle src={ARROW_AUTOFIT_WIDTH_ICON} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('canvas.zoomIn')}>
        <IconButton onClick={zoomIn}>
          <SvgIconStyle src={PLUS_ICON} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('canvas.zoomOut')}>
        <IconButton onClick={zoomOut}>
          <SvgIconStyle src={MINUS_ICON} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('canvas.rotateLeft')}>
        <IconButton onClick={rotateImageCounterClockwise}>
          <SvgIconStyle src={REFRESH_ICON} sx={{ transform: 'scaleX(-1)' }} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('canvas.rotateRight')}>
        <IconButton onClick={rotateImageClockwise}>
          <SvgIconStyle src={REFRESH_ICON} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

export { Controls };
