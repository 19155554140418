type GeneralConfig = {
  beamerId: string;
  beamerScriptId: string;
  beamerElementId: string;
  claimEnrichmentFormWidthKey: string;
  customer: string;
  defaultPaginationStart: number;
  defaultPaginationSize: number;
  defaultTableSort: 'ASC' | 'DESC';
  disableClaimAuditLog: boolean;
  disableClaimReviewScreen: boolean;
  disableNavDocuments: boolean;
  disableNavDocumentClosed: boolean;
  enableReactQueryDevtools: boolean;
  enableOpeningHilInNewTab: boolean;
  featureFlagsInterval: number;
  fullScreenReviewTool: string;
  gtmId: string;
  hilSearchWait: number;
  lockClaimInterval: number;
  lockDocumentInterval: number;
  lockedDocumentsStorage: string;
  miniNavbarStore: string;
  platformVersion: string;
  privacyPolicyUrl: string;
  rollbarAccessToken: string;
  rollbarEnvironment: string;
  sessionExpirationWarningDelay: number;
  sessionTimestampKey: string;
  showOCRIdentifiedKeyFields: boolean;
  showClaimsPerformance: boolean;
  showClaimTrafficLight: boolean;
};

const isProd = import.meta.env.PROD;
const customer =
  window.__env__.CUSTOMER || import.meta.env.VITE_CUSTOMER || 'base';
const defaultTableSort =
  window.__env__.DEFAULT_TABLE_SORT ||
  import.meta.env.VITE_DEFAULT_TABLE_SORT ||
  'ASC';
const disableNavDocuments =
  window.__env__.DISABLE_NAV_DOCUMENTS === 'true' ||
  import.meta.env.VITE_DISABLE_NAV_DOCUMENTS === 'true' ||
  false;
const sessionExpirationWarningDelay =
  parseInt(
    window.__env__.SESSION_EXPIRATION_WARNING_DELAY ||
      import.meta.env.VITE_SESSION_EXPIRATION_WARNING_DELAY
  ) || 8 * 60 * 60 * 1000; // default 8 hours
const disableClaimReviewScreen =
  window.__env__.DISABLE_CLAIM_REVIEW_SCREEN === 'true' ||
  import.meta.env.VITE_DISABLE_CLAIM_REVIEW_SCREEN === 'true';
const disableClaimAuditLog =
  window.__env__.DISABLE_CLAIM_AUDIT_LOG === 'true' ||
  import.meta.env.VITE_DISABLE_CLAIM_AUDIT_LOG === 'true';
const featureFlagsInterval =
  window.__env__.FEATURE_FLAGS_INTERVAL ||
  import.meta.env.VITE_FEATURE_FLAGS_INTERVAL;

const generalConfig: GeneralConfig = {
  beamerId: 'HFdIrZLD65713',
  beamerScriptId: 'beamer-script',
  beamerElementId: 'beamer-launcher',
  claimEnrichmentFormWidthKey: 'claimEnrichmentFormWidth',
  customer: customer.replace('-nonprod', ''),
  defaultPaginationStart: 0,
  defaultPaginationSize: 10,
  defaultTableSort,
  disableNavDocuments,
  disableClaimAuditLog,
  disableClaimReviewScreen,
  disableNavDocumentClosed:
    window.__env__.DISABLE_NAV_DOCUMENT_CLOSED === 'true' ||
    import.meta.env.VITE_DISABLE_NAV_DOCUMENT_CLOSED === 'true' ||
    false,
  enableOpeningHilInNewTab:
    window.__env__.ENABLE_HIL_NEW_TAB === 'true' ||
    import.meta.env.VITE_ENABLE_HIL_NEW_TAB === 'true',
  enableReactQueryDevtools: true,
  featureFlagsInterval: isProd
    ? parseInt(featureFlagsInterval)
    : 1000 * 60 * 60, // 60 seconds in prod, 1 hour in dev
  fullScreenReviewTool: 'fullScreenReviewTool',
  gtmId: window.__env__.GTM_ID || import.meta.env.VITE_GTM_ID,
  hilSearchWait: 400,
  lockClaimInterval: 3000,
  lockDocumentInterval: 3000,
  lockedDocumentsStorage: 'lockedDocuments',
  miniNavbarStore: 'miniNavbar',
  platformVersion:
    window.__env__.PLATFORM_VERSION || import.meta.env.VITE_PLATFORM_VERSION,
  privacyPolicyUrl: 'https://sprout.ai/privacy-policy/',
  rollbarAccessToken: 'a41f8349f26047e3839c41f4a7d6384a',
  rollbarEnvironment: customer,
  sessionExpirationWarningDelay, // 8 hours
  sessionTimestampKey: 'sessionTimestamp',
  showOCRIdentifiedKeyFields:
    window.__env__.ENABLE_OCR_IDENTIFIED_KEY_FIELDS === 'true' ||
    import.meta.env.VITE_ENABLE_OCR_IDENTIFIED_KEY_FIELDS === 'true',
  showClaimsPerformance:
    window.__env__.ENABLE_CLAIMS_PERFORMANCE === 'true' ||
    import.meta.env.VITE_ENABLE_CLAIMS_PERFORMANCE === 'true',
  showClaimTrafficLight:
    window.__env__.ENABLE_CLAIM_TRAFFIC_LIGHT === 'true' ||
    import.meta.env.VITE_ENABLE_CLAIM_TRAFFIC_LIGHT === 'true',
};

export { generalConfig };
