import type { FabricImage } from 'fabric';
import { filters } from 'fabric';
import { interpolateNumber } from 'd3';
import { findImage } from 'components/CanvasToolV2/canvas-utils.ts';

import type { FileCanvas } from './types.ts';
import { bindObjectsToImage, updateBoundObjects } from './utils.ts';

const THRESHOLDS = {
  brightness: {
    low: -0.5,
    high: 0.5,
  },
  skew: {
    low: -10,
    high: 10,
  },
} as const;

function settingsTools(getCanvas: () => FileCanvas) {
  const getImage = () =>
    getCanvas().getObjects().find(findImage) as FabricImage;

  const adjustBrightness = (value: number | number[]) => {
    const v = value as number;
    const canvas = getCanvas();
    const img = getImage();

    if (img) {
      const i = interpolateNumber(
        THRESHOLDS.brightness.low,
        THRESHOLDS.brightness.high
      );
      // zero because it's the first filter to be added
      if (!img.filters[0]) {
        img.filters[0] = new filters.Brightness({ brightness: i(v / 100) });
      } else {
        img.filters[0]['brightness'] = i(v / 100);
      }
      img.applyFilters();
      canvas.renderAll();
    }
  };

  const adjustSkewX = (value: number | number[]) => {
    const v = value as number;
    const canvas = getCanvas();
    const img = getImage();
    bindObjectsToImage(canvas);

    if (img) {
      const i = interpolateNumber(THRESHOLDS.skew.low, THRESHOLDS.skew.high);
      img.set('skewX', i(v / 100));
      updateBoundObjects(canvas);
      canvas.renderAll();
    }
  };

  const adjustSkewY = (value: number | number[]) => {
    const v = value as number;
    const canvas = getCanvas();
    const img = getImage();
    bindObjectsToImage(canvas);

    if (img) {
      const i = interpolateNumber(THRESHOLDS.skew.low, THRESHOLDS.skew.high);
      img.set('skewY', i(v / 100));
      updateBoundObjects(canvas);
      canvas.renderAll();
    }
  };

  return { adjustBrightness, adjustSkewX, adjustSkewY };
}

export { settingsTools };
