import { useMemo } from 'react';

import type { ThumbnailsProp } from './types.ts';
import { getGroupedThumbnails } from './thumbnail-utils.ts';
import { ThumbnailSinglePanel } from './ThumbnailSinglePanel.tsx';
import { ThumbnailsContainer } from './ThumbnailsContainer.tsx';
import { ThumbnailsMultiGroup } from './ThumbnailsMultiGroup.tsx';

function Thumbnails({ activePage, position, thumbnails }: ThumbnailsProp) {
  const groupedThumbnails = useMemo(() => {
    if (thumbnails) {
      return getGroupedThumbnails(thumbnails);
    }

    return {};
  }, [thumbnails]);
  const groupedThumbnailKeys = Object.keys(groupedThumbnails);
  const isSingleGroup = groupedThumbnailKeys.length === 1;

  if (isSingleGroup) {
    return (
      <ThumbnailsContainer>
        <ThumbnailSinglePanel
          activePage={activePage}
          disableBackButton
          group={groupedThumbnailKeys[0]}
          isActivePanel
          position={position}
          thumbnails={groupedThumbnails[groupedThumbnailKeys[0]]}
        />
      </ThumbnailsContainer>
    );
  }

  return (
    <ThumbnailsMultiGroup
      activePage={activePage}
      groupedThumbnails={groupedThumbnails}
      position={position}
      thumbnails={thumbnails}
    />
  );
}

export { Thumbnails };
