import { SvgIconStyle } from 'components/SvgIconStyle';
import { DialogTooltip } from 'components/dialog/Tooltip/DialogTooltip';
import {
  cancelButton,
  copyButton,
  deleteButton,
  unlockButton,
  header,
  text,
  actionButton,
} from 'components/dialog/generators';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { CLOSE_BOX_ICON, THREE_DOTS_ICON } from 'constants/public-icons';
import {
  useArchiveClaim,
  useCloseClaim,
  useUnlockClaim,
} from 'state/queries/claims';
import { copyToClipboard } from 'utils/client';

interface ClientTooltipProps {
  hasCloseClaim: boolean;
  id: string;
  isClaimAdmin: boolean;
  isSuperAdmin: boolean;
}

const ARCHIVE = 'archive';
const CLOSE = 'close';
const COPY = 'copy';
const UNLOCK = 'unlock';

function TableTooltip({
  hasCloseClaim,
  id,
  isClaimAdmin,
  isSuperAdmin,
}: ClientTooltipProps) {
  const { t } = useTranslationRoot();

  const archiveClaimAction = useArchiveClaim({
    id,
    meta: {
      errorMessage: 'Failed to archive claim',
      loadingMessage: 'Archiving claim',
      successMessage: 'Claim archived',
    },
  });

  const unlockClaimAction = useUnlockClaim(id);

  const closeClaimAction = useCloseClaim({
    id,
    meta: {
      errorMessage: t('tooltip.closeClaimError') as string,
      loadingMessage: t('tooltip.closeClaimLoading') as string,
      successMessage: t('tooltip.closeClaimSuccess') as string,
    },
  });

  return (
    <DialogTooltip
      getDialog={{
        index({ changePage, closeTooltip }) {
          return [
            isSuperAdmin &&
              copyButton({
                action() {
                  copyToClipboard(id);
                  changePage(COPY);
                  setTimeout(closeTooltip, 2000);
                },
                label: 'tooltip.copy',
              }),
            (isClaimAdmin || isSuperAdmin) &&
              actionButton({
                action() {
                  changePage(CLOSE);
                },
                hidden: !hasCloseClaim,
                icon: (
                  <SvgIconStyle height={15} width={15} src={CLOSE_BOX_ICON} />
                ),
                label: 'tooltip.closeClaim',
              }),
            isSuperAdmin &&
              unlockButton({
                action() {
                  changePage(UNLOCK);
                },
                label: 'tooltip.unlock',
              }),
            isSuperAdmin &&
              deleteButton({
                action() {
                  changePage(ARCHIVE);
                },
                label: 'tooltip.archive',
              }),
          ].filter(Boolean);
        },
        [CLOSE]: ({ closeTooltip }) => [
          header('tooltip.confirmCloseClaim'),
          deleteButton({
            action() {
              closeClaimAction.mutate({ id });
              closeTooltip();
            },
            label: 'tooltip.closeClaim',
          }),
          cancelButton({
            action() {
              closeTooltip();
            },
            namespace: '',
          }),
        ],
        [ARCHIVE]: ({ closeTooltip }) => [
          header('tooltip.confirmArchive'),
          deleteButton({
            action() {
              archiveClaimAction.mutate({ id });
              closeTooltip();
            },
            label: 'tooltip.archive',
          }),
          cancelButton({
            action() {
              closeTooltip();
            },
            namespace: '',
          }),
        ],
        [UNLOCK]: ({ closeTooltip }) => [
          header('tooltip.unlock'),
          unlockButton({
            action() {
              unlockClaimAction.mutate({ id, force: true });
              closeTooltip();
            },
            label: 'tooltip.unlock',
          }),
          cancelButton({
            action() {
              closeTooltip();
            },
            namespace: '',
          }),
        ],
        [COPY]: () => [text('tooltip.copySuccess')],
      }}
      testId={`settings-${id}`}
    >
      <SvgIconStyle height={20} width={20} src={THREE_DOTS_ICON} />
    </DialogTooltip>
  );
}

export { TableTooltip };
