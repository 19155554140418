import Stack from '@mui/material/Stack';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { RETRY_ICON } from 'constants/public-icons';

interface ErrorFallbackProps {
  errorMessage: string;
  retryButton: string;
  refetch: () => void;
}

function ErrorFallback({
  errorMessage,
  retryButton,
  refetch,
}: ErrorFallbackProps) {
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        py: 4,
        gap: 1,
      }}
    >
      <TypographyWithTranslation variant="body1" i18nKey={errorMessage} />
      <ButtonWithTranslation
        size="small"
        i18nKey={retryButton}
        onClick={() => refetch()}
        startIcon={<SvgIconStyle src={RETRY_ICON} />}
        sx={{
          ml: 1,
        }}
        variant="contained"
      />
    </Stack>
  );
}

export { ErrorFallback };
