import { useCallback, useMemo } from 'react';
import { type FieldValues, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import {
  isLineLevelItem,
  isNotLineLevelItem,
} from 'state/selectors/documents.ts';
import { generateUuid } from 'utils/generate-uuid.ts';

import type {
  DocumentContentWithId,
  DocumentLevelContentWithId,
  LineContentWithId,
  LineItemIdsAndResources,
  LineItemRow,
  Resources,
} from './types.ts';
import { DocumentItems } from './DocumentItems.tsx';
import { LineItemsWrapper } from './LineItemsWrapper.tsx';
import { LineItems } from './LineItems.tsx';
import { useEnrichmentToolContext } from './useEnrichmentToolContext.tsx';
import { generateLineItems, getGroupedLineItems } from './utils.ts';

interface FieldsProps {
  hasLineItems: boolean;
  items: DocumentContentWithId[];
  list: string[];
}

function Fields({ hasLineItems, items, list }: FieldsProps) {
  const { unregister, getValues, setValue } = useFormContext();
  const { state, setState } = useEnrichmentToolContext();
  const { columns, rows } = generateLineItems(items);

  const groupedAllLineItems = useMemo(() => {
    const items = Object.values(state.fields);
    const lines = items.filter(isLineLevelItem);
    return getGroupedLineItems(lines);
  }, [state.fields]);

  const prepareNewLineItems = (newLineIdx?: number) => {
    const exampleRow = [
      ...Object.values(groupedAllLineItems),
    ].pop() as DocumentLevelContentWithId[];
    const highestLineIdx = Object.values(groupedAllLineItems).reduce(
      (acc, row) => {
        const lineIdx = row[0].lineIdx as number;
        return lineIdx > acc ? lineIdx : acc;
      },
      0
    );

    return exampleRow.reduce(
      (acc, item) => {
        const id = generateUuid();
        const lineIdx = newLineIdx || highestLineIdx + 1;
        return {
          ...acc,
          ids: [...acc.ids, id],
          resources: {
            ...acc.resources,
            [id]: {
              ...item,
              deleted: false,
              id,
              lineIdx,
              source: 'HIL',
              valid:
                item.sproutaiKey === 'item_specialty' ||
                item.sproutaiKey === 'item_state_contribution_flag' ||
                item.sproutaiKey === 'item_state_contribution',
              value: '',
            },
          },
        } as LineItemIdsAndResources;
      },
      {
        ids: [],
        resources: {},
      } as LineItemIdsAndResources
    );
  };

  const getExistingFormValues = () => {
    // having to do this to stop the user from losing their previously typed
    // data before adding/removing line items
    const existingFormValues = getValues();

    return Object.keys(existingFormValues).reduce(
      (acc, key) => {
        acc[key] = {
          ...state.fields[key],
          value: existingFormValues[key] as string,
        };

        return acc;
      },
      { ...state.fields }
    );
  };

  const setEmptyValues = ({ ids, resources }: LineItemIdsAndResources) => {
    ids.forEach((id) => {
      const item = resources[id];

      if (item.valueType === 'bool') {
        setValue(id, false);
      } else {
        setValue(id, '');
      }
    });
  };

  const addLineItems = useCallback(
    () => {
      const existingFields = getExistingFormValues();
      const { ids, resources } = prepareNewLineItems();

      setEmptyValues({ ids, resources });

      setState((prevState) => ({
        ...prevState,
        lists: {
          ...prevState.lists,
          [prevState.fieldsListId]: [
            ...prevState.lists[prevState.fieldsListId],
            ...ids,
          ],
        },
        fields: {
          ...prevState.fields,
          ...existingFields,
          ...resources,
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.fields]
  );

  const getNextLineIdx = (items: LineContentWithId[], nextIndex: number) =>
    items.some((item) => item.lineIdx === nextIndex);

  const setFieldsDeleted = (ids: string[], existingFormValues: FieldValues) => {
    return ids.reduce((acc, id) => {
      acc[id] = {
        ...state.fields[id],
        deleted: true,

        // having to do this to stop the user from losing their previously typed
        // data before adding/removing line items
        value: existingFormValues[id],
      };
      return acc;
    }, state.fields);
  };

  const deleteLineItems = (row: LineItemRow) => {
    return () => {
      const existingFormValues = getValues();
      const { keyToId, lineIdx } = row;
      const ids = Object.values(keyToId) as string[];

      unregister(ids);

      const list = state.lists[state.fieldsListId];
      const filteredList = list.filter((id) => !ids.includes(id));

      // also might as well updates fields object at the same time
      const fields = setFieldsDeleted(ids, existingFormValues);

      // if ids does not exist in state._originalState.fields, then we need to remove them from fields
      ids.forEach((id) => {
        if (!state.__originalState.fields[id]) {
          delete fields[id];
        }
      });

      // check if the next lineIdx exists
      const lineItems = Object.values(fields).filter(
        isLineLevelItem
      ) as LineContentWithId[];
      const hasNextLineIdx = getNextLineIdx(lineItems, lineIdx + 1);

      // if yes then decrement the lineIdx of all the line items below the next
      // lineIdx, but not the original line items
      if (hasNextLineIdx) {
        const newFields = lineItems.reduce((acc, item) => {
          const { id } = item;

          // if the item exists in original state and the lineIdx is the same
          // then do nothing
          if (
            state.__originalState.fields[id] &&
            item.lineIdx === state.__originalState.fields[id].lineIdx
          ) {
            return acc;
          }

          if (item.lineIdx > lineIdx) {
            acc[id] = {
              ...item,
              lineIdx: item.lineIdx - 1,
            };
          }

          return acc;
        }, fields as Resources);

        setState((prevState) => ({
          ...prevState,
          lists: {
            ...prevState.lists,
            [prevState.fieldsListId]: filteredList,
          },
          fields: newFields,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          lists: {
            ...prevState.lists,
            [prevState.fieldsListId]: filteredList,
          },
          fields,
        }));
      }

      Object.keys(existingFormValues).forEach((key) => {
        setValue(key, existingFormValues[key]);
      });
    };
  };

  const filterList =
    (condition: (item: DocumentContentWithId) => boolean) => (id: string) => {
      const item = state.fields[id];

      return condition(item);
    };

  const deleteAllLineItems = () => {
    const existingFormValues = getValues();

    const list = state.lists[state.fieldsListId];
    const filteredList = list.filter(filterList(isNotLineLevelItem));
    const lineItemsList = list.filter(filterList(isLineLevelItem));

    unregister(lineItemsList);
    // also might as well updates fields object at the same time
    const updatedFields = setFieldsDeleted(lineItemsList, existingFormValues);

    // if ids does not exist in state._originalState.fields, then we need to remove them from fields
    lineItemsList.forEach((id) => {
      if (!state.__originalState.fields[id]) {
        delete updatedFields[id];
      }
    });

    // prepare new line items
    const { ids: newLineItemIds, resources: newLineItemFields } =
      prepareNewLineItems();
    setEmptyValues({ ids: newLineItemIds, resources: newLineItemFields });

    const fields = {
      ...updatedFields,
      ...newLineItemFields,
    };

    setState((prevState) => ({
      ...prevState,
      lists: {
        ...prevState.lists,
        [prevState.fieldsListId]: [...filteredList, ...newLineItemIds],
      },
      fields,
    }));

    Object.keys(existingFormValues).forEach((key) => {
      setValue(key, existingFormValues[key]);
    });
  };

  const addLineBelow = (index: number) => () => {
    const nextIndex = index + 1;
    const lineItems = Object.values(state.fields).filter(
      isLineLevelItem
    ) as LineContentWithId[];

    // check if the next lineIdx already exists
    const hasNextLineIdx = getNextLineIdx(lineItems, nextIndex);

    // if yes then increment the lineIdx of all the line items below the next lineIdx
    if (hasNextLineIdx) {
      const oldFields = lineItems.reduce((acc, item) => {
        const { id } = item;

        if (item.lineIdx >= nextIndex) {
          acc[id] = {
            ...item,
            lineIdx: item.lineIdx + 1,
          };
        }

        return acc;
      }, state.fields);
      const { ids, resources } = prepareNewLineItems(nextIndex);
      const newFields = {
        ...oldFields,
        ...resources,
      };
      const oldList = [...state.lists[state.fieldsListId], ...ids];
      const newNonLineItemIdsList = oldList.filter((id) => {
        const item = newFields[id] as LineContentWithId;
        return item.lineIdx === null;
      });
      const newLineItemIdsList = oldList.filter((id) => {
        const item = newFields[id] as LineContentWithId;
        return item.lineIdx !== null;
      });
      const sortedLineItemsList = newLineItemIdsList.sort((a, b) => {
        const itemA = newFields[a] as LineContentWithId;
        const itemB = newFields[b] as LineContentWithId;

        if (itemA.lineIdx === null && itemB.lineIdx === null) return 0;
        if (itemA.lineIdx === null) return -1;
        if (itemB.lineIdx === null) return 1;
        return itemA.lineIdx - itemB.lineIdx;
      });

      setState((prevState) => ({
        ...prevState,
        lists: {
          ...prevState.lists,
          [prevState.fieldsListId]: [
            ...newNonLineItemIdsList,
            ...sortedLineItemsList,
          ],
        },
        fields: newFields,
      }));
    } else {
      // normal add lines
      addLineItems();
    }
  };

  const documentItems = items.filter(isNotLineLevelItem);
  //clear the policynumber field when client memid is manually entered ON KEYUP
  const policyNumberId =
    list.find((id) => state.fields[id].sproutaiKey === 'policy_number') ?? '';

  const node = (
    <DocumentItems items={documentItems} policyNumberId={policyNumberId} />
  );

  return hasLineItems ? (
    <LineItemsWrapper
      addLineItems={addLineItems}
      deleteAllLineItems={deleteAllLineItems}
      fields={node}
      lineItems={
        <LineItems
          addLineBelow={addLineBelow}
          columns={columns}
          deleteLineItems={deleteLineItems}
          rows={rows}
        />
      }
    />
  ) : (
    <Box
      sx={{
        width: 1,
      }}
    >
      {node}
    </Box>
  );
}

export { Fields };
