import { createSelector } from 'reselect';
import { SUPER_ADMIN, CLAIM_ADMIN } from 'constants/roles';
import { transformAuthResponse } from 'api/transformers/auth.ts';

import { selectState } from './utils.ts';

export const selectCurrentUserResponse = createSelector(
  selectState,
  transformAuthResponse
);

export const selectFirstName = createSelector(
  selectCurrentUserResponse,
  (user) => user.firstName
);

export const selectFullName = createSelector(
  selectCurrentUserResponse,
  (user) => `${user.firstName} ${user.lastName}`
);

export const selectEmail = createSelector(
  selectCurrentUserResponse,
  (user) => user.email
);

export const selectRole = createSelector(
  selectCurrentUserResponse,
  (user) => user.role
);

export const isSuperAdminRole = createSelector(
  selectCurrentUserResponse,
  (user) => user.role === SUPER_ADMIN
);

export const isClaimAdminRole = createSelector(
  selectCurrentUserResponse,
  (user) => user.role === CLAIM_ADMIN
);
