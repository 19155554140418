import { Suspense } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { Page } from 'components/Page';
import { WithTranslationRoot } from 'components/with-translation';
import { LoadingIcon } from 'components/icons/LoadingIcon.tsx';
import { TypographyWithTranslation } from 'components/with-translation';
import { usePages } from 'components/customHooks/usePages.ts';
import { CONFIGURATION_PAGE } from 'constants/translation-keys';
import { useGetAppConfigs } from 'state/queries/appconfig';
import { toTitleCase } from 'utils/string.ts';

import { LoadVersion } from './LoadVersion.tsx';

const HIDDEN_CONFIGS = ['app_config'];

function AppConfig() {
  const { data, isPending, isError } = useGetAppConfigs();
  const { handleChangePage, isPending: isPagePending, page } = usePages();

  const filteredData =
    data?.filter(({ config }) => !HIDDEN_CONFIGS.includes(config)) || [];

  return (
    <WithTranslationRoot namespace={CONFIGURATION_PAGE}>
      <Page i18nKeyTitle="appConfig.title">
        {isPending ? (
          <LoadingIcon />
        ) : isError ? (
          <div>Failed to get list of app configs.</div>
        ) : filteredData.length ? (
          <TabContext value={page}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChangePage}
                aria-label="App Config tabs"
                variant="scrollable"
                scrollButtons={false}
              >
                {filteredData.map(({ config: name }, index) => (
                  <Tab
                    key={name}
                    label={toTitleCase(name)}
                    value={String(index)}
                  />
                ))}
              </TabList>
            </Box>

            <Suspense fallback={<LoadingIcon />}>
              <div style={{ opacity: isPagePending ? 0.8 : 1 }}>
                {filteredData.map(({ config }, index) => (
                  <TabPanel key={config} value={String(index)}>
                    <LoadVersion name={config} />
                  </TabPanel>
                ))}
              </div>
            </Suspense>
          </TabContext>
        ) : (
          <TypographyWithTranslation i18nKey="appConfig.noConfigs" />
        )}
      </Page>
    </WithTranslationRoot>
  );
}

export default AppConfig;
