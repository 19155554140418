import type { CropState } from './types.ts';
import { ActionType, dispatch as cropDispatch } from './useCropListener.ts';
import { dispatch as pageDispatch } from './usePageListener.ts';

export const canvasCustomEvents = {
  updatePageEvent(newPage: number) {
    pageDispatch(newPage);
  },
  addCrop(crop: CropState) {
    cropDispatch({ type: ActionType.ADD_CROP, crop });
  },
};
