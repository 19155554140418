import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { ARROW_FORWARD_ICON } from 'constants/public-icons.ts';
import { toTitleCase } from 'utils/string.ts';

import type { ThumbnailMenuProps } from './types.ts';

function ThumbnailMenu({
  group,
  handleChange,
  isActive,
  index,
}: ThumbnailMenuProps) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={(e) => handleChange(e, group, true)}
        sx={{
          bgcolor: ({ palette }) =>
            isActive ? alpha(palette.primary.main, 0.08) : 'transparent',
          p: 0,
          pl: 1,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderTop: index > 0 ? '1px solid' : 'none',
            borderColor: 'divider',
            color: isActive ? 'primary.main' : 'text.primary',
            display: 'flex',
            justifyContent: 'space-between',
            py: 1,
            pr: 0.5,
            width: 1,
          }}
          data-testid="thumbnail-menu-container"
        >
          <ListItemText
            primary={toTitleCase(group)}
            primaryTypographyProps={{
              sx: {
                fontSize: ({ typography }) => typography.pxToRem(12),
                fontWeight: 600,
                wordBreak: 'break-all',
              },
            }}
          />
          <SvgIconStyle src={ARROW_FORWARD_ICON} height={14} width={14} />
        </Box>
      </ListItemButton>
    </ListItem>
  );
}

export { ThumbnailMenu };
