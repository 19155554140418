// viewport control constnats
export const SCALE_STEP = 0.1;
export const ZOOM_IN_THRESHOLD = 5;
export const ZOOM_OUT_THRESHOLD = 0.1;

// crop (bounding box) constants
export const ADDITIONAL_CROP_SIZE = 0.1; // 10%

// panels
export const DEFAULT_PANEL = 'menu';
