import type { FabricObject } from 'fabric';
import { FabricImage, FabricText, Path, Point, util } from 'fabric';
import type { FilePage } from 'types/Files.ts';
import type { BoundingBox } from 'components/CanvasToolV2/types.ts';

import type { FabricObjectWithRelationship, FileCanvas } from './types.ts';
import { customEvent } from './events.ts';

export const HEADER_ID = 'image-editor-header';
export const CANVAS_CONTAINER_ID = 'image-editor-canvas-container';
export const SCALE_STEP = 0.1;
export const ZOOM_IN_THRESHOLD = 5;
export const ZOOM_OUT_THRESHOLD = 0.1;
export const ADDITIONAL_CROP_SIZE = 0.1; // 10%
export const THUMBNAIL_EVENT = 'add-thumbnail';

export const findImage = (obj: FabricObject) => obj.isType('image');
export const findGroup = (obj: FabricObject) => obj.isType('group');
export const findRect = (obj: FabricObject) => obj.isType('rect');
export const findPath = (obj: FabricObject) => obj.isType('path');

type Dimensions = {
  height: number;
  width: number;
};

function getElementRect(element: HTMLDivElement) {
  return element.getBoundingClientRect();
}

// function urlContentToDataUri(url) {
//   return fetch(url)
//     .then((response) => response.blob())
//     .then(
//       (blob) =>
//         new Promise((callback) => {
//           const reader = new FileReader();
//           reader.onload = function () {
//             callback(this.result);
//           };
//           reader.readAsDataURL(blob);
//         })
//     );
// }

function loadFabricImage(url: string) {
  // return urlContentToDataUri(url).then((dataUri) =>
  //   loadImageWithSrc(dataUri as string)
  // );

  return FabricImage.fromURL(
    url,
    { crossOrigin: 'anonymous' },
    {
      hasControls: false,
      hasBorders: false,
      hoverCursor: 'default',
      moveCursor: 'none',
      selectable: false,
      lockMovementX: true,
      lockMovementY: true,
      originX: 'center',
      originY: 'center',
    }
  );
}

export function loadImageWithSrc(src: string) {
  return new Promise<FabricImage>((resolve) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      resolve(
        new FabricImage(image, {
          hasControls: false,
          hasBorders: false,
          hoverCursor: 'default',
          moveCursor: 'none',
          selectable: false,
          lockMovementX: true,
          lockMovementY: true,
          originX: 'center',
          originY: 'center',
        })
      );
    };
  });
}

export function getScaleFactor(
  { height, width }: Dimensions,
  img: FabricImage | FabricObject,
  offset: number = 1
) {
  const containerHeight = height;
  let scaleFactor = width / img.width;
  const scaledHeight = img.height * scaleFactor;

  // if the image is too tall, scale it down to fit the container height
  if (scaledHeight > containerHeight) {
    scaleFactor = containerHeight / img.height;
  }

  return scaleFactor * offset;
}

export async function initApp({
  canvas,
  urls,
}: {
  canvas: FileCanvas;
  urls: FilePage[];
}) {
  // cache elements
  const containerEl = document.getElementById(
    CANVAS_CONTAINER_ID
  ) as HTMLDivElement;

  // resize canvas to fit container
  const { height, width } = getElementRect(containerEl);
  const dimensions = {
    height,
    width,
  };
  canvas.setDimensions(dimensions);
  canvas.pageId = urls[0].pageId;

  // add image to canvas
  try {
    await loadImage({
      canvas,
      dimensions,
      url: urls[0].pageUrl,
      onSuccess: () => {
        canvas.page = 0;
      },
    });
  } catch (error) {
    throw new Error('Failed to load image');
  }
}

export async function loadImage({
  canvas,
  dimensions,
  onSuccess,
  url,
}: {
  canvas: FileCanvas;
  dimensions: Dimensions;
  onSuccess?: (image: FabricImage) => void;
  url: string;
}) {
  try {
    const image = await loadFabricImage(url);
    const scaleFactor = getScaleFactor(dimensions, image);
    image.scale(scaleFactor);
    canvas.add(image);
    canvas.fire(customEvent.IMAGE_ADDED);
    canvas.centerObject(image);
    onSuccess?.(image);
  } catch (error) {
    throw new Error('Failed to load image');
  }
}

export function bindObjectsToImage(canvas: FileCanvas) {
  const objects = canvas.getObjects();
  const image = objects.find(findImage) as FabricObject;
  const redacts = objects.filter(findPath) as FabricObjectWithRelationship[];

  if (redacts.length) {
    const imageTransform = image.calcTransformMatrix();
    const invertedImageTransform = util.invertTransform(imageTransform);

    redacts.forEach((o) => {
      o.relationship = util.multiplyTransformMatrices(
        invertedImageTransform,
        o.calcTransformMatrix()
      );
    });
  }
}

export function updateBoundObjects(canvas: FileCanvas) {
  const objects = canvas.getObjects();
  const image = objects.find(findImage) as FabricObject;
  const redacts = objects.filter(findPath) as FabricObjectWithRelationship[];

  if (redacts.length) {
    redacts.forEach((o) => {
      const relationship = o.relationship;
      const newTransform = util.multiplyTransformMatrices(
        image.calcTransformMatrix(),
        relationship
      );
      const opt = util.qrDecompose(newTransform);
      o.set({
        flipX: false,
        flipY: false,
      });
      o.setPositionByOrigin(
        new Point({
          x: opt.translateX,
          y: opt.translateY,
        }),
        'center',
        'center'
      );
      o.set(opt);
      o.setCoords();
    });
  }
}

export function generateRedactPath({
  height,
  width,
}: {
  height: number;
  width: number;
}) {
  return `M0 0L${width} ${height}M0 ${height}L${width} 0M0 0H${width}V${height}H0V0Z`;
}

export function addErrorText({
  canvas,
  content,
  error,
  onImageLoadError,
}: {
  canvas: FileCanvas;
  content: string;
  error: Error;
  onImageLoadError?: (error: Error) => void;
}) {
  onImageLoadError?.(error as Error);

  const text = new FabricText(content, {
    top: 8,
    left: 8,
    fontFamily: 'Public Sans,sans-serif',
    fontSize: 16,

    hasControls: false,
    hasBorders: false,
    hoverCursor: 'default',
    moveCursor: 'none',
    selectable: false,
  });
  canvas.add(text);
}

function getBoundingBox({ topLeft, bottomRight }: BoundingBox) {
  const [left, top] = topLeft;
  const [right, bottom] = bottomRight;

  return {
    bottom,
    left,
    right,
    top,
  };
}

export function initPreviousRedactions({
  canvas,
  prevRedactions,
}: {
  canvas: FileCanvas;
  prevRedactions: {
    boundingBox: {
      bottomLeft: [number, number];
      bottomRight: [number, number];
      topLeft: [number, number];
      topRight: [number, number];
    };
    identifier: string;
  }[];
}) {
  const image = canvas.getObjects().find(findImage) as FabricImage;

  if (image && prevRedactions?.length) {
    prevRedactions.forEach(({ boundingBox, identifier }) => {
      const scale = image.get('scaleX');

      // don't forget to account for image originY and originX
      const imageHeight = image.get('height');
      const imageTopOffset = (canvas.getHeight() - imageHeight * scale) / 2;
      const imageWidth = image.get('width');
      const imageLeftOffset = (canvas.getWidth() - imageWidth * scale) / 2;

      const { bottom, left, right, top } = getBoundingBox(boundingBox);
      const width = right - left;
      const height = bottom - top;

      const path = new Path(
        generateRedactPath({ width: width * scale, height: height * scale }),
        {
          left: Math.ceil(imageLeftOffset + (left + width / 2) * scale),
          top: Math.ceil(imageTopOffset + (top + height / 2) * scale),
          width: Math.ceil(width * scale),
          height: Math.ceil(height * scale),
          fill: 'black',
          stroke: 'grey',
          border: 'none',
          uniformScaling: false,
          cornerColor: 'blue',
          transparentCorners: false,
          cornerStrokeColor: 'white',
          cornerStyle: 'circle',
          originX: 'center',
          originY: 'center',
          sproutaiKey: identifier,
        }
      );
      canvas.add(path);
    });

    canvas.fire(customEvent.REDACT_ADDED);
  }
}
