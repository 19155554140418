// User Behaviour Events
// Enrichment Tool
export const ENRICHMENT_TOOL_START = 'cr_tool_start'; // from when Enrichment Tool loads
export const ENRICHMENT_TOOL_END = 'cr_tool_end'; // till Enrichment Tool unloads
export const ENRICHMENT_SUBMIT_START = 'cr_submit_start'; // from when the user clicks on submit button
export const ENRICHMENT_SUBMIT_END = 'cr_submit_end'; // till the submit function ends

// Review Screen
export const REVIEW_SCREEN_START = 'cr_tool_start'; // from when Review Screen loads
export const REVIEW_SCREEN_END = 'cr_tool_end'; // till Review Screen unloads
export const REVIEW_SCREEN_SUBMIT_START = 'cr_submit_start'; // from when the user clicks on submit button
export const REVIEW_SCREEN_SUBMIT_END = 'cr_submit_end'; // till the submit function ends

// HIL Tool
export const HIL_VALIDATION_FAIL = 'doc_correction_validation_fail';
export const HIL_OPEN_END = 'doc_correction_open_end'; // from when hil tool loads to image finishes loading
export const HIL_TOOL_SUBMIT_START = 'doc_correction_submit_start'; // from when the user clicks on submit button
export const HIL_TOOL_SUBMIT_END = 'doc_correction_submit_end'; // till the submit function ends
export const HIL_TOOL_START = 'doc_correction_start'; // from when HIL Tool loads
export const HIL_TOOL_END = 'doc_correction_end'; // till HIL Tool unloads

// APIs
// Enrichment Tool
export const ENRICHMENT_VALIDATION_START = 'adv_validation_endpoint_start';
export const ENRICHMENT_VALIDATION_END = 'adv_validation_endpoint_end';
export const ENRICHMENT_PATCH_START = 'adv_patch_endpoint_start';
export const ENRICHMENT_PATCH_END = 'adv_patch_endpoint_end';
export const ENRICHMENT_NEXT_CLAIM_START = 'next_claim_endpoint_start';
export const ENRICHMENT_NEXT_CLAIM_END = 'next_claim_endpoint_end';

// Review Screen
export const REVIEW_SCREEN_VALIDATION_START = 'cr_validation_endpoint_start';
export const REVIEW_SCREEN_VALIDATION_END = 'cr_validation_endpoint_end';
export const REVIEW_SCREEN_PATCH_START = 'cr_correction_endpoint_start';
export const REVIEW_SCREEN_PATCH_END = 'cr_correction_endpoint_end';
export const REVIEW_SCREEN_NEXT_CLAIM_START = 'next_claim_endpoint_start';
export const REVIEW_SCREEN_NEXT_CLAIM_END = 'next_claim_endpoint_end';

// HIL Tool
export const HIL_TOOL_VALIDATION_START = 'doc_validation_endpoint_start';
export const HIL_TOOL_VALIDATION_END = 'doc_validation_endpoint_end';
export const HIL_TOOL_PATCH_START = 'doc_correction_endpoint_start';
export const HIL_TOOL_PATCH_END = 'doc_correction_endpoint_end';
export const HIL_TOOL_GET_NEXT_DOCUMENT_START = 'next_document_endpoint_start';
export const HIL_TOOL_GET_NEXT_DOCUMENT_END = 'next_document_endpoint_end';
