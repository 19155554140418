import type { FabricImage } from 'fabric';
import { Rect } from 'fabric';

import type { BoundingBox, CanvasWithPage } from './types.ts';
import { ADDITIONAL_CROP_SIZE } from './constants.ts';
import { getBoundingBox } from './canvas-utils.ts';

type ConfigureRect = {
  boundingBox: BoundingBox;
  canvas: CanvasWithPage;
  img: FabricImage;
  scale: number;
  strokeColor: string;
};

export function configureRect({
  canvas,
  boundingBox,
  img,
  scale,
  strokeColor,
}: ConfigureRect) {
  const { bottom, left, right, top } = getBoundingBox(boundingBox);
  const width = right - left;
  const height = bottom - top;
  const additionalWidth = width * ADDITIONAL_CROP_SIZE;
  const additionalHeight = height * ADDITIONAL_CROP_SIZE;

  const offsetLeft = (canvas.width - img.width * img.scaleX) / 2;
  const offsetTop = (canvas.height - img.height * img.scaleY) / 2;

  return new Rect({
    top: offsetTop + (top + (height / 2 + additionalHeight)) * scale,
    left: offsetLeft + (left + width / 2) * scale,
    width: (width + additionalWidth) * scale,
    height: (height + additionalHeight) * scale,

    // prevent the object being a "clickable" point
    // to enable drag and select
    evented: false,

    fill: 'transparent',
    flipX: false,
    flipY: false,
    hasBorders: false,
    hasControls: false,
    originX: 'center',
    originY: 'center',
    selectable: false,
    stroke: strokeColor,
    strokeWidth: 2,
  });
}
