import TabPanel from '@mui/lab/TabPanel';
import { useTabContext } from '@mui/lab/TabContext';

import type { ThumbnailPanelProps } from './types.ts';
import { ThumbnailSinglePanel } from './ThumbnailSinglePanel.tsx';

function ThumbnailPanel(props: ThumbnailPanelProps) {
  const { group } = props;
  const tabContext = useTabContext();

  return (
    <TabPanel key={group} value={group} sx={{ height: 1 }}>
      <ThumbnailSinglePanel
        {...props}
        isActivePanel={tabContext?.value === group}
      />
    </TabPanel>
  );
}

export { ThumbnailPanel };
