import type { Column } from 'components/table/AdvancedTable.tsx';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import { Page } from 'components/Page';
import {
  ButtonWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { PERFORMANCE_MANAGE_USERS_PAGE } from 'constants/translation-keys';
import { useGetUsersData, useGetUsersTotal } from 'state/queries/users';
import { useCurrentUserEmail } from 'state/queries/current-user';
import { LinkWithRef } from 'components/Link';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { USER_ICON } from 'constants/public-icons';
import performanceCreateUserRoute from 'pages/PerformanceCreateUser/performanceCreateUser.route';
import { useAdvancedTablePagination } from 'components/customHooks/useAdvancedTablePagination.ts';

import { Role } from './Role';
import { UsersTable } from './UsersTable';

function PerformanceManageUsers() {
  const pagination = useAdvancedTablePagination({});
  const { page, rowsPerPage } = pagination as {
    page: number;
    rowsPerPage: number;
  };

  const usersQuery = useGetUsersData(page, rowsPerPage);
  const usersDataTotal = useGetUsersTotal(page, rowsPerPage);
  const currentUserEmailQuery = useCurrentUserEmail();

  return (
    <WithTranslationRoot namespace={PERFORMANCE_MANAGE_USERS_PAGE}>
      <Page i18nKeyTitle="userManagement.title">
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              action={
                <ButtonWithTranslation
                  color="primary"
                  component={LinkWithRef}
                  i18nKey="userManagement.addAccountButton"
                  size="small"
                  startIcon={
                    <SvgIconStyle src={USER_ICON} width={13.5} height={13.5} />
                  }
                  sx={{
                    fontSize: 13,
                  }}
                  to={performanceCreateUserRoute.createPath()}
                  variant="outlined"
                />
              }
            />
            <UsersTable
              columns={
                [
                  {
                    id: 'email',
                    i18nKey: 'userManagement.email',
                  },
                  {
                    id: 'role',
                    i18nKey: 'userManagement.role',
                    renderCell: (params) => (
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <Role
                          page={page}
                          rowsPerPage={rowsPerPage}
                          user={params.row}
                          isCurrentUser={
                            params.row.email === currentUserEmailQuery.data
                          }
                        />
                      </Box>
                    ),
                    custom: true,
                    rowSx: {
                      width: '100%',
                    },
                  },
                ] as Column[]
              }
              rows={usersQuery.data ? usersQuery.data : []}
              rowIdentifier="email"
              isPending={usersQuery.isPending}
              totalCount={usersDataTotal.data ?? 0}
              noItemsLabeli18nKey="userManagement.noItems"
              pagination={pagination}
              isError={usersQuery.isError}
              refetch={usersQuery.refetch}
              errorMessage="userManagement.error"
              retryButton="userManagement.retryButton"
            />
          </Card>
        </Container>
      </Page>
    </WithTranslationRoot>
  );
}

export default PerformanceManageUsers;
