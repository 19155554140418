import { useCallback, useEffect, useRef, useState } from 'react';
import { Canvas } from 'components/CanvasToolV2/Canvas.tsx';
import { useResizeCanvas } from 'components/CanvasToolV2/useResizeCanvas.ts';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { findImage } from 'components/CanvasToolV2/canvas-utils.ts';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { wrap } from 'utils/math.ts';
import rollbar from 'rollbar-config.ts';

import type { FileCanvas } from './types.ts';
import { clearCorrections, Correction, getRedactObjects } from './state.ts';
import {
  getCorrections,
  restoreRedactsFromCorrections,
  writeToCorrections,
} from './state.ts';
import { View } from './View.tsx';
import { Header } from './Header.tsx';
import {
  addErrorText,
  bindObjectsToImage,
  getScaleFactor,
  initApp,
  initPreviousRedactions,
  loadImage,
  loadImageWithSrc,
  updateBoundObjects,
} from './utils.ts';
import { Thumbnails } from './Thumbnails.tsx';
import { usePage } from './usePage.ts';
import { initEventListeners } from './event-listeners.ts';
import { cropTool } from './crop-tool.ts';
import { settingsTools } from './settings-tools.ts';
import { customEvent } from './events.ts';
import { NoChangesModal } from './NoChangesModal.tsx';

interface ImageEditingToolProps {
  disableSubmitButton: boolean;
  isSubmitting: boolean;
  mode: 'crop' | 'redact';
  onImageLoadError?: (error: Error) => void;
  onNoChanges: () => void;
  onSubmit: (corrections: Map<number, Correction>) => void;
  prevRedactions?: {
    boundingBox: {
      bottomLeft: [number, number];
      bottomRight: [number, number];
      topLeft: [number, number];
      topRight: [number, number];
    };
    identifier: string;
  }[];
  urls: { pageId: string; pageUrl: string }[];
}

function ImageEditingTool({
  disableSubmitButton,
  isSubmitting,
  mode,
  onImageLoadError,
  onNoChanges,
  onSubmit,
  prevRedactions,
  urls,
}: ImageEditingToolProps) {
  const enableToolDebug = useFeatureFlag('enableDebugMode');
  const { t } = useTranslationRoot();
  const { page, handleChangePage } = usePage();
  const { clearCrop, deleteCrop, getCropBySubPage, handleCrop } = cropTool();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [noChangesMade, setNoChangesMade] = useState(false);
  const ref = useRef<FileCanvas>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const getCanvas = () => ref.current as FileCanvas;
  const getContainerEl = () => containerRef.current as HTMLDivElement;

  const onLoad = useCallback(
    async (canvas: FileCanvas) => {
      setHasLoaded(true);

      if (ref.current) {
        ref.current.page = 0;
      }

      try {
        clearCorrections();
        clearCrop();

        await initApp({
          canvas,
          urls,
        });

        initEventListeners({ canvas });

        if (prevRedactions) {
          initPreviousRedactions({ canvas, prevRedactions });
        }
      } catch (error) {
        addErrorText({
          canvas,
          error: error as Error,
          onImageLoadError,
          content: t('common.failedToLoadImage'),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urls]
  );

  const { adjustBrightness, adjustSkewX, adjustSkewY } =
    settingsTools(getCanvas);

  useEffect(() => {
    if (hasLoaded) {
      return () => {
        const canvas = getCanvas();

        if (canvas) {
          canvas.fire(customEvent.TOOL_UNMOUNT);
          void canvas.dispose();
        }
      };
    }
  }, [hasLoaded]);

  useResizeCanvas({
    callbackFn: ({ height, width }) => {
      const canvas = getCanvas();

      if (canvas) {
        const img = canvas.getObjects().find(findImage);

        if (img) {
          bindObjectsToImage(canvas);

          const scale = getScaleFactor({ height, width }, img);
          img.scale(scale);
          canvas.setDimensions({
            width,
            height,
          });
          canvas.centerObject(img);
          updateBoundObjects(canvas);
        }
      }
    },
    canvas: getCanvas(),
    element: getContainerEl(),
    hasLoaded,
    offsetHeight: 0,
  });

  const loadNewImage = async (newPage: number) => {
    const canvas = getCanvas();
    const dimensions = { height: canvas.getHeight(), width: canvas.getWidth() };

    canvas.clear();

    await loadImage({
      canvas,
      dimensions,
      onSuccess: (image) => {
        canvas.sendObjectToBack(image);
      },
      url: urls[newPage].pageUrl,
    });
  };

  const asyncChangePage = async (oldPage: number, newPage: number) => {
    try {
      await writeToCorrections({
        canvas: getCanvas(),
        page: oldPage,
        pageId: urls[oldPage].pageId,
      });
    } catch (error) {
      console.error('Failed to do redaction backup', error);
      if (enableToolDebug) {
        rollbar.info(
          'Failed to do redaction backup',
          error as unknown as Error
        );
      }
    }

    await handleChangePage(newPage);

    try {
      await loadNewImage(newPage);
    } catch (error) {
      console.error('Failed to load new image', error);
      if (enableToolDebug) {
        rollbar.info('Failed to load new image', error as unknown as Error);
      }
    }

    restoreRedactsFromCorrections({
      canvas: getCanvas(),
      page: newPage,
    });

    const canvas = getCanvas();
    canvas.page = newPage;
    canvas.fire(customEvent.PAGE_UPDATED);
    canvas.pageId = urls[newPage].pageId;
  };

  const nextPage = async () => {
    const newPage = wrap(0, urls.length, page + 1);
    void asyncChangePage(page, newPage);
  };

  const prevPage = async () => {
    const newPage = wrap(0, urls.length, page - 1);
    void asyncChangePage(page, newPage);
  };

  const onThumbnailClick = async (newPage: number, newSubPage?: number) => {
    if (newPage !== page) {
      void handleChangePage(newPage);
    }

    if (newSubPage === undefined) {
      void asyncChangePage(page, newPage);
    }

    const canvas = getCanvas();

    if (newSubPage === undefined) {
      canvas.page = newPage;
    }

    if (typeof newSubPage === 'number') {
      canvas.clear();

      const crop = getCropBySubPage(newPage, newSubPage);

      if (crop) {
        const object = await loadImageWithSrc(crop.url as string);
        object.scale(crop.scale);
        canvas.fire(customEvent.IMAGE_ADDED);
        canvas.add(object);
        canvas.centerObject(object);
        canvas.page = `${newPage}.${newSubPage}`;
        canvas.fire(customEvent.PAGE_UPDATED);
      }
    }
  };

  const onCrop = async () => {
    try {
      const { cropObject, subPage, src } = await handleCrop({
        canvas: getCanvas(),
        page,
      });

      await writeToCorrections({
        canvas: getCanvas(),
        cropObject,
        overrideSnapshot: src,
        page,
        pageId: urls[page].pageId,
        isCrop: true,
        subPage,
      });
    } catch (error) {
      console.error(error, 'failed to crop');
    }
  };

  const onDeleteThumbnail = (page: number, subPage: number) => {
    deleteCrop(page, subPage);
    void asyncChangePage(page, page);
  };

  const handleSubmit = async () => {
    try {
      const canvas = getCanvas();
      bindObjectsToImage(canvas);
      const image = canvas.getObjects().find(findImage);
      const redactObjects = getRedactObjects(canvas);

      /**
       * doing this specifically for redaction feature
       * reset the scale of image and redacted objects
       * before returning the redaction coordinates to backend
       */
      if (image && redactObjects.length) {
        const prevScale = image.scaleX;
        image.scale(1);
        image.set({
          left: image.width / 2,
          top: image.height / 2,
        });
        redactObjects.forEach((o) => {
          o.set({
            originX: 'left',
            originY: 'top',
            width: o.width / prevScale,
            height: o.height / prevScale,
          });
          o.scale(1);
        });
        updateBoundObjects(canvas);
      }

      // carry out final redaction backup, if any
      await writeToCorrections({
        canvas,
        page,
        pageId: urls[page].pageId,
      });
    } catch (error) {
      console.error('Failed to do final redaction backup', error);
    }

    const corrections = getCorrections();

    if (corrections.size) {
      onSubmit(corrections);
    } else {
      setNoChangesMade(true);
      return;
    }
  };

  return (
    <>
      <View
        header={
          hasLoaded && (
            <Header
              canvas={ref.current as FileCanvas}
              getCanvas={getCanvas}
              handleBrightnessChange={adjustBrightness}
              handleSkewXChange={adjustSkewX}
              handleSkewYChange={adjustSkewY}
              handleNextPage={nextPage}
              handlePrevPage={prevPage}
              disableSubmitButton={disableSubmitButton}
              isSubmitting={isSubmitting}
              mode={mode}
              onCrop={onCrop}
              onSubmit={handleSubmit}
              page={page}
              total={urls.length}
            />
          )
        }
        containerRef={containerRef}
        thumbnails={
          hasLoaded && (
            <Thumbnails
              onThumbnailClick={onThumbnailClick}
              onDeleteThumbnail={onDeleteThumbnail}
              page={page}
              urls={urls}
            />
          )
        }
      >
        <Canvas ref={ref} onLoad={onLoad} />
      </View>
      <NoChangesModal
        closeModal={() => {
          setNoChangesMade(false);
        }}
        isOpen={noChangesMade}
        onSubmit={onNoChanges}
      />
    </>
  );
}

export { ImageEditingTool };
