import type { Canvas as CanvasType } from 'fabric';
import { useEffect } from 'react';
import debounce from '@mui/utils/debounce';

function useResizeCanvas({
  callbackFn,
  canvas,
  element,
  hasLoaded = false,
  offsetHeight = 0,
}: {
  callbackFn: (args: DOMRect) => void;
  canvas: CanvasType;
  element: Element;
  hasLoaded: boolean;
  offsetHeight?: number;
}) {
  useEffect(() => {
    const resize = debounce(() => {
      const rect = element.getBoundingClientRect();
      const { height, width } = rect;
      const newHeight = Math.abs(height - offsetHeight);

      // bizarre issue that fixes "this.lower is undefined" bug
      if (canvas.lowerCanvasEl) {
        canvas.setDimensions({
          width,
          height: newHeight,
        });
      }

      callbackFn?.(rect);
    }, 100);

    if (hasLoaded) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentRect) {
            // note: I did not realise contentRect would return top: 0
            // not good for our use case.
            resize();
          }
        }
      });

      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded]);
}

export { useResizeCanvas };
