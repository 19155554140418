import type { Thumbnail, ThumbnailWithPageIdx } from './types.ts';

export function getGroupedThumbnails(thumbnails: Thumbnail[]) {
  return thumbnails.reduce(
    (acc, thumbnail, index) => {
      if (!acc[thumbnail.documentGroup]) {
        acc[thumbnail.documentGroup] = [];
      }

      acc[thumbnail.documentGroup].push({ ...thumbnail, pageIdx: index });
      return acc;
    },
    {} as Record<string, ThumbnailWithPageIdx[]>
  );
}
